import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import Playbutton from './extra/Playbutton';
import TicketDetails from './extra/TicketDetails';
import TicketOrder from './extra/TicketOrder';
import TicketReview from './extra/TicketReview';
import GetBalance from './extra/GetBalance';
import { format } from 'date-fns';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Lottery = ({ userInfo }) => {

    const [betnumberlist, setBetNumberList] = useState([])

    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    
    const [picktime, setPickTime] = useState([
        {"date":"0000-00-00", "time":"00:00", "status":"inactive"}, 
        {"date":"0000-00-00", "time":"00:00", "status":"inactive"}, 
        {"date":"0000-00-00", "time":"00:00", "status":"inactive"}
    ])
    const [wallet, setWallet] = useState({"balance": "0.00"})
    const [orderlist, setOrderList] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("pick_time", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setPickTime(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    const loadWallet = async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("orderlist2", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setOrderList(res.data)
            console.log(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    const orderlistLoad = async()=> {
        var formData = new FormData();
        formData.append("orderlist2", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setOrderList(res.data)
            // console.log(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header hidebalance={true} />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{backgroundImage: `url(${require('./includes/sball.png')})`}}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid" style={{paddingBottom:10}}>
                        <section className="content-header">
                            {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Lottery</h4>
                                    </div>
                                </div>
                            </div> */}
                        </section>

                        <div className='row'>
                            <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", padding: 10, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff", marginBottom: 10}}>
                                {/* <span onClick={()=>window.location.assign('/wallet')} style={{padding: 5, border: "1px solid #ddd", backgroundColor: "#17a2b8", color: "#fff", borderRadius: 5, fontWeight: 600}}>Buy Credits</span> */}
                                <div>Balance</div>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end"}}>
                                    {/* <div>Balance</div> */}
                                    <div>
                                        <GetBalance wallet={wallet} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div style={{display: "flex", flexDirection: "column", gap: 5, flexGrow: 1}}>
                                {
                                    picktime.map((item, key) => {
                                        return (
                                            <div key={key} style={{width: "100%", height: 70, opacity: item.status == 'inactive' ? 0.2 : 1, borderRadius: 5, border:"1px solid #ddd", backgroundColor: item.status == 'active' ? "#fff" : "#ddd", display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems: "center"}}>
                                                <div style={{padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems:"center", gap: 5}}>
                                                    <div style={{width:50, height:50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: 20, fontWeight: "bolder"}} className='bg-info'>STL</div>
                                                    <div>
                                                        <div>{item.date}</div>
                                                        <div><span style={{fontSize: 12}}>Draw Time:</span> {item.drawtime}</div>
                                                    </div>
                                                </div>
                                                <div style={{padding: 5}}>
                                                    {
                                                        item.status == 'active' ?
                                                        (
                                                            <Playbutton item={item} orderlistLoad={orderlistLoad} betnumberlist={betnumberlist} setBetNumberList={setBetNumberList} loadWallet={loadWallet} wallet={wallet} />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <TicketReview pick={1} orderlistLoad={orderlistLoad} betnumberlist={betnumberlist} setBetNumberList={setBetNumberList} loadWallet={loadWallet} wallet={wallet} />
                            </div>
                        </div>
                        <hr />
                        <h5 class="mb-2" style={{fontWeight: "bolder", color: "#000"}}>TICKET</h5>
                        <div className='row'>
                            <div style={{display: "flex", flexDirection: "column", gap: 5, width: "100%"}}>
                                {/* {
                                    
                                    Object.entries([...orderlist]?.reduce((groups, item) => {
                                        const { time_draw } = item;                                                            
                                        if (!groups[time_draw]) {
                                            groups[time_draw] = [];
                                        }
                                        groups[time_draw].push(item);
                                        return groups;
                                    }, {})).map(([time_draw, items]) => {
                                        return (

                                            <div style={{ padding: 10, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", boxShadow: "0px 1px 2px grey" }}>
                                                <div style={{ fontWeight: "bolder", fontSize: 20 }}>Draw: {time_draw === '10:45 AM' ? '11:00 AM' : time_draw === '02:45 PM' ? '03:00 PM' : '07:00 PM'}</div>
                                                <div style={{fontWeight: "bolder"}}>Game: STL-SILAY</div>
                                                <div style={{fontWeight: "bolder"}}>Date: {format(new Date(items[0].date_time), 'MMMM dd, yyyy')}</div>
                                                <span style={{fontSize: 20, fontWeight: "bolder"}}>Total: {[...items[0]?.list]?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                <div style={{ border: "1px solid #ddd", width: "100%" }}></div>
                                                <div>
                                                    {
                                                        [...items[0]?.list].map((item) => {
                                                            return (
                                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                                    <div>Ticket: <span style={{fontWeight: "bolder"}}>{item?.number}</span></div>
                                                                    <div>{Number(item?.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                } */}
                                {
                                    orderlist.length > 0 ?
                                    (
                                        orderlist.map((item)=>{
                                            return (
                                                <TicketOrder item={item} />
                                            )
                                        })
                                    ) : (
                                        <div style={{width:"100%", textAlign: "center"}}>No Data</div>
                                    )
                                }
                            </div>
                        </div>
                        {/* <div>
                            <div style={{paddingTop: 5, paddingBottom: 5}}>
                                <div>0000-00-00</div>
                            </div>
                            <div className="row">
                                <div style={{display: "flex", flexDirection: "column", gap: 5, flexGrow: 1}}>
                                    {
                                        [1,2,3,4,5].map(()=>{
                                            return (
                                                <TicketDetails />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div> */}
                        {/* <div>
                            <div style={{paddingTop: 5, paddingBottom: 5}}>
                                <div>0000-00-00</div>
                            </div>
                            <div className="row">
                                <div style={{display: "flex", flexDirection: "column", gap: 5, flexGrow: 1}}>
                                    {
                                        [1,2,3,4,5].map(()=>{
                                            return (
                                                <TicketDetails />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div> */}
                        {/* /.row (main row) */}
                    </div>
                    <br />
                    <br />
                    <br />
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 1, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Lottery;
