// import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import swal from 'sweetalert';
import './commission.css';

const ConvertForm = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({ "user_id": sessionStorage.getItem('user_id'), "convert_balance": 0 })

    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const convertBtn = async (event) => {
        event.preventDefault();
        
        if (Number(inputvalue.convert_balance) === 0) {            
            swal({
                text: "please complete the form!",
                icon: "error",
                timer: 1000,
                button: false,
            });
            return;
        }

        var formData = new FormData();
        formData.append("convert_balance", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            if (res.data[0].status === 'success') {
                swal({
                    text: `Converted: ${res.data[0].converted}`,
                    icon: "success",
                    timer: 1000,
                    button: false,
                });
            } else if (res.data[0].status === 'error') {
                swal({
                    text: `Invalid`,
                    icon: "error",
                    timer: 1000,
                    button: false,
                });
            } else if (res.data[0].status === 'invalid') {
                swal({
                    text: `Insufficient Balance`,
                    icon: "error",
                    timer: 1000,
                    button: false,
                });
            }
        });

    }
    
    const sendRequet = async (event) => {
        event.preventDefault();

        var formData = new FormData();
        formData.append("reqest_payout", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));

        if (inputvalue.amount<0) {
            swal({
                title: "Invalid!",
                text: "Invalid Amount",
                icon: "error",
                timer: 2000,
                button: false,
            });
            return;
        }

        if (inputvalue.account_name === '' || inputvalue.account_number === '' || inputvalue.amount < 0) {
            swal({
                title: "Invalid!",
                text: "Please complete the form",
                icon: "error",
                timer: 1000,
                button: false,
            });
            return;
        }


        if (props?.wallet?.balance >= Number(inputvalue.amount)) {
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/process.php',
                data: formData,
            }).then((res) => {
                if (res.data[0].status == 'success') {
                    swal({
                        // title: "",
                        text: "Successfully Sent Request",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        setOpenDialog(prev => ({ ...prev, open: false }))
                        setInputValue({ "user_id": sessionStorage.getItem('user_id'), "account_name": "", "account_number": "", "amount": '' })
                        props.reloadWallet()
                        props.reloadPayoutList()
                    });
                } else if (res.data[0].status == 'invalid_amount') {
                    swal({
                        // title: "Invalid!",
                        text: "invalid Amount",
                        icon: "error",
                        // timer: 2000,
                        button: false,
                    });
                }
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            swal({
                // title: "Invalid!",
                text: "not enough balance!",
                icon: "error",
                timer: 1000,
                button: false,
            });
        }
    }

    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>Conversion</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 200, fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>
                <form onSubmit={convertBtn} method="post">
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 10 }}>
                        <div>
                            <label>Balance: </label> <label>{Number(props.commission - props.wallet.conversion)}</label>    
                        </div>
                        <div>
                            <label>Convert Amount</label>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                <input className='form-control' name="convert_balance" placeholder='Convert Balance' style={{textAlign: "right"}} onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }))} />
                                <button style={{width: 150, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#317bb0", color: "#fff"}} >Convert</button>
                            </div>                            
                        </div>
                    </div>
                </form>
            </Sidebar>
            <a onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{textDecoration: "underline"}}>Convert</a>

            {/* <button onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{ width: 100 }} className='btn btn-info btn-sm'>Payout</button> */}
        </>
    );
}

export default ConvertForm;
