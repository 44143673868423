import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import './wallet.css';
import Header from '../Header';
import Navbar from '../Navbar';

const CreditHistory = ({userInfo}) => {
    const [credithistory, setCreditHistoryList] = useState([])
    useMemo(async () => {
        var formData = new FormData();
        formData.append("credithistory", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setCreditHistoryList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header hidebalance={true} titlepage="Credit History" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ backgroundImage: `url(${require('../../includes/sball.png')})` }}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* <section className="content-header"> */}
                        {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div> */}
                        {/* </section> */}

                        <div className="row">
                            <div className="col-lg-3 col-12">
                                {/* <div className="small-box bg-info">
                                    <div className="">
                                        <div style={{fontWeight: "bolder", fontSize: 35}}>STL</div>
                                        <div style={{fontWeight: "bolder", fontSize: 20}}>00-00</div>
                                        <div>Reward: 0.00</div>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-shopping-cart" />
                                    </div>
                                    <a href="/#" className="small-box-footer">
                                        Claim <i className="fas fa-arrow-circle-right" />
                                    </a>
                                </div> */}
                            </div>
                        </div>


                        {/* /.row (main row) */}
                    </div>

                    <div className="row">
                        {/* Left col */}
                        {/* /.Left col */}
                        {/* right col (We are only adding the ID to make the widgets sortable)*/}
                        <div className='col-sm-12'>
                            <div style={{padding: 5, marginBottom: 50}}>
                                <h5 class="mb-2">HISTORY</h5>
                                <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                    {
                                        [...credithistory].filter((i) => i.user_id === sessionStorage.getItem('user_id')).map((item) => {
                                            return (
                                                <div className="info" style={{ display: "flex", flexDirection: "column", gap: 0, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                        <span>Credit: {Number(item?.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                    </div>
                                                    <span style={{ fontSize: 12 }}>{item?.date_time}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.row (main row) */}
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{ position: "fixed", zIndex: 1, bottom: 0, width: "100%", backgroundColor: "#fff" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", flexShrink: 1 }}>
                        <div onClick={() => window.location.assign("/dashboard")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Home</div>
                        <div style={{ height: 30, width: 1, backgroundColor: "#ddd" }}></div>
                        <div onClick={() => window.location.assign("/lottery")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Play</div>
                        <div style={{ height: 30, width: 1, backgroundColor: "#ddd" }}></div>
                        <div onClick={() => window.location.assign("/account")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );



    return (
        <div className="row">
            <div className='hidescroll' style={{display: 'flex', flexDirection: "column", gap: 5, width: "100%", height: window.innerWidth === 320 ? 260 : null, overflowX: "auto"}}>
                {
                    [...credithistory].filter((i)=>i.user_id === sessionStorage.getItem('user_id')).map((item)=>{
                        return (
                            <div className="info" style={{display: "flex", flexDirection: "column", gap: 0, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                    
                                </div>
                                <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                    <span>Amount: {item?.amount}</span>
                                </div>
                                <span style={{fontSize: 12}}>{item?.date_time}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default CreditHistory;
