import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import WalletHistory from './component/wallet/WalletHistory';
import WalletForm from './component/wallet/WalletForm';
import CreditForm from './component/wallet/CreditForm';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Wallet = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';


    const [wallet, setWallet] = useState({ "balance": "0.00" })
    const [payoutlist, setPayoutList] = useState([])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("payoutlist", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setPayoutList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setWallet(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    const reloadPayoutList = async () => {
        var formData = new FormData();
        formData.append("payoutlist", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setPayoutList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }
    const reloadWallet = async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setWallet(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }


    // useEffect(()=>{ // Time
    //     const myInterval = setInterval(()=>{
    //         setSec(sec+1);
    //         if (sec === 59) {
    //             setMin(min+1)
    //             setSec(0);
    //         }
    //         if (min === 59) {
    //             setHr(hr+1)
    //             setMin(0)
    //             setSec(0);
    //         }
    //             setCount(count-1)
    //     }, 1000);
    //     console.log(hr+' : '+min+' : '+sec);
    //     return () => clearInterval(myInterval)
    // }, [count]);


    // console.log(window.innerWidth <= 768)
    // function domReady(fn) {
    //     if (document.readyState === "complete" || document.readyState === "interactive") {
    //         setTimeout(fn, 1);
    //     } else {
    //         document.addEventListener("DOMContentLoaded", fn);
    //     }
    // }

    // domReady(function () {
    //     var myqr = document.getElementById("you-qr-result");
    //     var lastResult, countResults = 0;
    //     function onScanSuccess(decodeText, decodeResult) {
    //         if (decodeText !== lastResult) {
    //             ++countResults;
    //             lastResult = decodeText;

    //             alert("you Qr is: " + decodeText, decodeResult);
    //             myqr.innerHTML = `you scan ${countResults} : ${decodeText}`;

    //         }
    //     }
    //     var htmlscanner = new Html5QrcodeScanner("preview", { fps: 10, qrbox: 250 })

    //     htmlscanner.render(onScanSuccess)
    // })

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header hidebalance={true} titlepage="Wallet" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ backgroundImage: `url(${require('./includes/sball.png')})` }}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* <section className="content-header"> */}
                        {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div> */}
                        {/* </section> */}

                        <div className="row">
                            <div className="col-lg-3 col-12">
                                {/* <div className="small-box bg-info">
                                    <div className="">
                                        <div style={{fontWeight: "bolder", fontSize: 35}}>STL</div>
                                        <div style={{fontWeight: "bolder", fontSize: 20}}>00-00</div>
                                        <div>Reward: 0.00</div>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-shopping-cart" />
                                    </div>
                                    <a href="/#" className="small-box-footer">
                                        Claim <i className="fas fa-arrow-circle-right" />
                                    </a>
                                </div> */}
                            </div>
                        </div>


                        {/* /.row (main row) */}
                    </div>

                    <div className="row">
                        {/* Left col */}
                        {/* /.Left col */}
                        {/* right col (We are only adding the ID to make the widgets sortable)*/}
                        <div className='col-sm-12'>
                            <div className="info-box">
                                <div className="info-box-content" style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <span className="info-box-text" style={{ fontSize: 20 }}>Balance</span>
                                        <span className="info-box-text" style={{ fontWeight: "bolder", fontSize: 25, display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                            <i className='pi pi-database' />
                                            <GetBalance wallet={wallet} reloadWallet={reloadWallet} />
                                        </span>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                            <WalletForm wallet={wallet} reloadWallet={reloadWallet} payoutlist={payoutlist} reloadPayoutList={reloadPayoutList} />
                                            <CreditForm wallet={wallet} reloadWallet={reloadWallet} payoutlist={payoutlist} reloadPayoutList={reloadPayoutList} />

                                            {/* <span onClick={()=>window.location.assign('/wallet')} style={{padding: 5, border: "1px solid #ddd", backgroundColor: "#17a2b8", color: "#fff", borderRadius: 5, fontWeight: 600}}>Buy Credits</span> */}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-end", gap: 0 }}>
                                            {
                                                wallet.approve_payout > 0 ?
                                                    <span><span style={{ fontSize: 12 }}>Total Payout:</span> {Number(wallet.approve_payout).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                    : null
                                            }
                                            {
                                                wallet.pending_payout > 0 ?
                                                    <span><span style={{ fontSize: 12 }}>Pending:</span> {Number(wallet.pending_payout).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{padding: 5, marginBottom: 50}}>
                                <h5 class="mb-2">HISTORY</h5>
                                <div>
                                    <WalletHistory payoutlist={payoutlist} reloadPayoutList={reloadPayoutList} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.row (main row) */}
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{ position: "fixed", zIndex: 1, bottom: 0, width: "100%", backgroundColor: "#fff" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", flexShrink: 1 }}>
                        <div onClick={() => window.location.assign("/dashboard")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Home</div>
                        <div style={{ height: 30, width: 1, backgroundColor: "#ddd" }}></div>
                        <div onClick={() => window.location.assign("/lottery")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Play</div>
                        <div style={{ height: 30, width: 1, backgroundColor: "#ddd" }}></div>
                        <div onClick={() => window.location.assign("/account")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Wallet;
