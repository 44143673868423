import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import swal from 'sweetalert';
import GetBalance from './GetBalance';

const TicketReview = (props) => {
    const [visible, setVisible] = useState(false);
    // const [data, setData] = useState(props?.betnumberlist?.length > 0 ? props.betnumberlist : []);

    // console.log(props?.betnumberlist)


    const handleClick = () => {
        setVisible(false)
    }
    const handleOpenClick = () => {
        setVisible(true)
    }

    const remove = (item) => {
        const newData = props?.betnumberlist.filter((i) => i !== item);
        props.setBetNumberList(newData)
        if (newData.length > 0) {
            return;
        }
        setVisible(false)
    }

    const Total = props?.betnumberlist?.reduce((amount, item) => amount = Number(item.amount) + amount, 0);

    const proceed = async () => {
        // alert(props.wallet.balance>=Total)


        const newData = props.betnumberlist.map((item) => ({ ...item, "status": "sending" }))
        // console.log([...newData])
        props.setBetNumberList([...newData])

        // return;
        if (props.wallet.balance >= Total) {
            var formData = new FormData();
            formData.append("send_ticket", 1);
            formData.append("user_id", sessionStorage.getItem('user_id'));
            formData.append("betnumber", JSON.stringify(props.betnumberlist));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/process.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                window.location.assign('/lottery')
                // if (res.data.length>0) {
                //     const newData = res.data.filter((i) => {
                //         return i.leftnumber === [...props.betnumberlist].filter((s) => s.leftnumber === i.leftnumber)[0]?.leftnumber && i.rightnumber === [...props.betnumberlist].filter((s) => s.rightnumber === i.rightnumber)[0]?.rightnumber
                //     }).map((item) => item)
                //     props.setBetNumberList([...newData])
                // }

                    // console.log(newData)
                    // props.setBetNumberList([])
                    // console.log(res.data)
                    // props.loadWallet()
                    // props.orderlistLoad()
                    // setVisible(false)

                // if ([...res.data].filter((i)=>i.invalid_ticket === 1).length>0) {
                //     swal({
                //         text: "Sorry no available Ticket",
                //         icon: "info",
                //         timer: 1000,
                //         button: false,
                //     }).then(()=>{
                //         if ([...res.data].filter((i)=>i.status === 'success').length>0) {
                //             swal({
                //                 text: "Successfully Sent",
                //                 icon: "success",
                //                 timer: 1000,
                //                 button: false,
                //             }).then(()=>{
                //                 props.setBetNumberList([])
                //                 console.log(res.data)
                //                 props.loadWallet()
                //                 props.orderlistLoad()
                //                 setVisible(false)
                //             });
                //         } else if ([...res.data].filter((i)=>i.timeout === 1).length>0) {
                //             swal({
                //                 text: "Timeout",
                //                 icon: "info",
                //                 timer: 1000,
                //                 button: false,
                //             }).then(()=>{
                //                 props.setBetNumberList([])
                //                 console.log(res.data)
                //                 props.loadWallet()
                //                 props.orderlistLoad()
                //                 setVisible(false)
                //             });
                //         } else {
                //             props.setBetNumberList([])
                //             console.log(res.data)
                //             props.loadWallet()
                //             props.orderlistLoad()
                //             setVisible(false)
                //         }
                //     });
                // } else if ([...res.data].filter((i)=>i.status === 'success').length>0) {
                //     swal({
                //         text: "Successfully Sent",
                //         icon: "success",
                //         timer: 1000,
                //         button: false,
                //     }).then(()=>{
                //         props.setBetNumberList([])
                //         console.log(res.data)
                //         props.loadWallet()
                //         props.orderlistLoad()
                //         setVisible(false)
                //     });
                // }
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            swal({
                text: "Insufficient Balance",
                icon: "info",
                timer: 1000,
                button: false,
            }).then(()=>{
                const newData = props.betnumberlist.map((item) => ({ ...item, "status": "" }))
                props.setBetNumberList([...newData])
            })
        }

    }

    return (
        <>
            <Dialog
                closable={false}
                header={
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div>Review</div>
                        <div>Bal: <GetBalance wallet={props.wallet} /></div>
                    </div>
                }
                maximized={true}
                footer={
                    <>
                        <button onClick={proceed} className='btn btn-success btn-sm'>Proceed</button>
                        <button onClick={handleClick} className='btn btn-danger btn-sm'>Close</button>
                    </>
                }
                visible={visible}>
                <div>

                    {/* <div onClick={() => setVisible(true)} onHide={handleClick}>
                        <div style={{ fontWeight: "bolder" }}>Game: STL-SILAY</div>
                        <div style={{ fontWeight: "bolder" }}>Trans ID: 00000</div>
                        <div style={{ fontWeight: "bolder" }}>Trans Date: 0000-00-00</div>
                    </div> */}
                    <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        {
                            props?.betnumberlist.map((item, key) => {
                                return (
                                    <div>
                                        <div key={key} style={{ fontSize: 12, borderRadius: 5, border: "1px solid #ddd", padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>{item.leftnumber}-{item.rightnumber}</div>
                                            <div>STD</div>
                                            <div>{item.time_draw}</div>
                                            <div>{item.amount}</div>
                                            <div>
                                                {
                                                    item.status === 'sending' ?
                                                        <div style={{ padding: 1, cursor: "pointer", paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "#28a745", color: "#fff" }}>Sending</div>
                                                        :
                                                        item.status === 'declined' ?
                                                            <div style={{ padding: 1, cursor: "pointer", paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "rgb(243 54 102)", color: "#fff" }}>Declined</div>
                                                            :
                                                            item.status === 'no_ticket' ?
                                                                <div style={{ padding: 1, cursor: "pointer", paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "rgb(243 54 102)", color: "#fff" }}>No available ticket</div>
                                                                :
                                                                item.status === 'success' ?
                                                                    <div style={{ padding: 1, cursor: "pointer", paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "#28a745", color: "#fff" }}>Sent</div>
                                                                    :
                                                                    <div onClick={() => remove(item)} style={{ padding: 1, cursor: "pointer", paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "rgb(243 54 102)", color: "#fff" }}>Remove</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div>
                        <div style={{ fontSize: 15, fontWeight: "bolder" }}>Total: {Total}</div>
                    </div>
                </div>

            </Dialog>

            {
                props.pick === 1 ? (
                    <div>
                        {
                            props?.betnumberlist.length > 0 ?
                                (
                                    <>
                                        <hr />
                                        <div onClick={handleOpenClick} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                            <div style={{ padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 5, backgroundColor: "rgb(104 190 159)", color: "#fff" }}>Review your Number</div>
                                        </div>
                                    </>
                                )
                                : null
                        }
                    </div>
                ) : (
                    <>
                        {
                            props?.betnumberlist.length > 0 ?
                                (
                                    <div onClick={handleOpenClick} style={{ position: "absolute", left: 35 }}>
                                        <span style={{ color: "red", fontWeight: "bolder", cursor: "pointer" }}>Review</span>
                                    </div>
                                ) : null
                        }
                    </>
                )
            }
        </>
    );
}

export default TicketReview;
