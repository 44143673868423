import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useMemo, useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';

const BetAmount = (props) => {
    
    // var betnumber = JSON.stringify([]);
    // if(sessionStorage.getItem('betnumber')) {
    //     var betnumber = sessionStorage.getItem('betnumber');
    //     // console.log(1)
    // }
      
    // const [betnumberlist, setbetnumberList] = useState(JSON.parse(betnumber))

    const [visible, setVisible] = useState(false);
    const [amount, setAmount] = useState(0);

    const handleClick = () => {
        setVisible(false)
        setAmount(0)
    }

    console.log(props.wallet.balance)

    const handleClickAmount = async (item) => {
        if (props.leftnumber != '' && props.rightnumber != '') {
            // alert(item+' => '+props.leftnumber + "-" + props.rightnumber)
            const index = props.betnumberlist.findIndex((i)=> i.leftnumber == props.leftnumber && i.rightnumber == props.rightnumber)
            
            // alert(props?.betnumberlist[index]?.leftnumber+" "+props?.betnumberlist[index]?.rightnumber)
            // if (props?.betnumberlist[index]?.leftnumber != props?.leftnumber && props?.betnumberlist[index]?.rightnumber != props?.rightnumber) {
            
            if (props.wallet.balance >= item) {
                if (props?.leftnumber != props?.rightnumber) {
                    // props.setBetNumberList([...props.betnumberlist, {"leftnumber": props?.leftnumber, "rightnumber":props?.rightnumber, "amount": item, "time_draw": props.item.time, "drawtime": props.item.drawtime, "timespan": new Date().toLocaleDateString(), "user_id": sessionStorage.getItem('user_id')}])
                    
                    var formData = new FormData();
                    formData.append("send_ticket", 1);
                    formData.append("user_id", sessionStorage.getItem('user_id'));
                    // formData.append("betnumber", JSON.stringify(props.betnumberlist));
                    formData.append("leftnumber", props?.leftnumber);
                    formData.append("rightnumber", props?.rightnumber);
                    formData.append("amount", item);
                    formData.append("time_draw", props.item.time);
                    formData.append("drawtime", props.item.drawtime);
                    formData.append("timespan", new Date().toLocaleDateString());
                    formData.append("user_id", sessionStorage.getItem('user_id'));
                    await axios({
                        method: "post",
                        url: ENV.DOMAIN + 'api/process.php',
                        data: formData,
                    }).then((res) => {
                        // console.log(res.data);
                        if (res.data[0].status === 'success') {
                            swal({
                                text: "Successfully Sent",
                                icon: "success",
                                timer: 1000,
                                button: false,
                            }).then(()=>{
                                props.loadWallet()
                                props.setLeftNumber('')
                                props.setRightNumber('')
                                setVisible(false)
                                props.orderlistLoad()
                            });
                        } else if (res.data[0].status === 'no_ticket') {
                            swal({
                                text: "Sorry No Ticket Available",
                                icon: "info",
                                timer: 1000,
                                button: false,
                            }).then(()=>{
                                props.setLeftNumber('')
                                props.setRightNumber('')
                                setVisible(false)
                            });
                        } else if (res.data[0].status === 'declined') {
                            swal({
                                text: "Sorry! we declined your ticket this time",
                                icon: "error",
                                timer: 1000,
                                button: false,
                            }).then(()=>{
                                props.setLeftNumber('')
                                props.setRightNumber('')
                                setVisible(false)
                            });
                        }
                    });
                    return;             
                } else {
                    swal({
                        text: "Duplicate number found",
                        icon: "info",
                        timer: 1000,
                        button: false,
                    }).then(()=>{
                        props.setLeftNumber('')
                        props.setRightNumber('')
                    });
                    return;
                }     
            } else {                
                swal({
                    text: "Insufficient Balance",
                    icon: "info",
                    timer: 1000,
                    button: false,
                }).then(()=>{
                    const newData = props.betnumberlist.map((item) => ({ ...item, "status": "" }))
                    props.setBetNumberList([...newData])
                })
            }
            
            
            // sessionStorage.setItem("betnumber", JSON.stringify([...betnumberlist, {"leftnumber": props?.leftnumber, "rightnumber":props?.rightnumber, "amount": item, "timespan": new Date().toLocaleDateString()}]))
            // setVisible(false)
        }
    }

    const AddPlay = async () => {
        if (props.leftnumber != '' && props.rightnumber != '') {
            const index = props.betnumberlist.findIndex((i)=> i.leftnumber == props.leftnumber && i.rightnumber == props.rightnumber);
            if (props.wallet.balance >= amount) {
                if (props?.leftnumber && props?.rightnumber) {
                    if (amount >= 5) {
                        var formData = new FormData();
                        formData.append("send_ticket", 1);
                        formData.append("user_id", sessionStorage.getItem('user_id'));
                        // formData.append("betnumber", JSON.stringify(props.betnumberlist));
                        formData.append("leftnumber", props?.leftnumber);
                        formData.append("rightnumber", props?.rightnumber);
                        formData.append("amount", amount);
                        formData.append("time_draw", props.item.time);
                        formData.append("drawtime", props.item.drawtime);
                        formData.append("timespan", new Date().toLocaleDateString());
                        formData.append("user_id", sessionStorage.getItem('user_id'));
                        await axios({
                            method: "post",
                            url: ENV.DOMAIN + 'api/process.php',
                            data: formData,
                        }).then((res) => {
                            // console.log(res.data);
                            if (res.data[0].status === 'success') {
                                swal({
                                    text: "Successfully Sent",
                                    icon: "success",
                                    timer: 1000,
                                    button: false,
                                }).then(()=>{
                                    props.loadWallet()
                                    props.setLeftNumber('')
                                    props.setRightNumber('')
                                    setVisible(false)
                                    setAmount(0)
                                    props.orderlistLoad()
                                });
                            } else if (res.data[0].status === 'no_ticket') {
                                swal({
                                    text: "Sorry No Ticket Available",
                                    icon: "info",
                                    timer: 1000,
                                    button: false,
                                }).then(()=>{
                                    props.setLeftNumber('')
                                    props.setRightNumber('')
                                    setVisible(false)
                                    setAmount(0)
                                });
                            } else if (res.data[0].status === 'declined') {
                                swal({
                                    text: "Sorry! we declined your ticket this time",
                                    icon: "error",
                                    timer: 1000,
                                    button: false,
                                }).then(()=>{
                                    props.setLeftNumber('')
                                    props.setRightNumber('')
                                    setVisible(false)
                                    setAmount(0)
                                });
                            } else if (res.data[0].status === 'limit') {
                                swal({
                                    text: "Sorry No Ticket Available",
                                    icon: "error",
                                    timer: 2000,
                                    button: false,
                                }).then(()=>{
                                    // props.setLeftNumber('')
                                    // props.setRightNumber('')
                                    // setVisible(false)
                                    setAmount(0)
                                });
                            }
                        });
                        return;  
                    } else {
        
                    }
    
                } else {
                    swal({
                        text: "Duplicate number found",
                        icon: "info",
                        timer: 2000,
                        button: false,
                    }).then(()=>{
                        props.setLeftNumber('')
                        props.setRightNumber('')
                        // setVisible(false)
                        setAmount(0)
                    });
                }
            } else {                
                swal({
                    text: "Insufficient Balance",
                    icon: "info",
                    timer: 1000,
                    button: false,
                }).then(()=>{
                    const newData = props.betnumberlist.map((item) => ({ ...item, "status": "" }))
                    props.setBetNumberList([...newData])
                })
            }
        }
    }

    useMemo(()=> {        
        // console.log(props.betnumberlist)
        // sessionStorage.removeItem("betnumber")
    }, [props])
    return (
        <>
            <Dialog
                closable={false}
                header={
                    <>
                        <span>Enter Amount</span>
                    </>
                }
                style={{ width: '90vw' }}
                footer={
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                        <button onClick={handleClick} className='btn btn-danger btn-sm'>CLOSE</button>
                        {
                            amount >= 5 ?
                            <button onClick={AddPlay} className='btn btn-info btn-sm'>ADD</button>
                            :
                            <button className='btn btn-default btn-sm'>ADD</button>
                        }
                    </div>
                }
                visible={visible}>

                <div style={{display:"flex", flexDirection: "column", gap: 10}}>
                    <div>
                        <input type='number' onChange={(e)=>setAmount(e.target.value)} value={amount!=0 ? amount : null} className='form-control' placeholder='0.00' />
                    </div>
                    {/* <div>
                        <span style={{fontWeight: "bolder"}}>Balance: 0.00</span>
                    </div> */}
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, alignItems:"center", flexWrap: "wrap"}}>
                        {
                            [5, 10, 15, 20, 25, 30, 40, 50, 70, 80, 100].map((item) => {
                                return (
                                    <div onClick={()=>handleClickAmount(item)} key={item} style={{display:"flex", fontWeight: "bolder", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 50, padding: 10, borderRadius: 2, border: "1px solid #ddd"}}>{item}</div>
                                )
                            })
                        }
                    </div>
                </div>

            </Dialog>

            {
                props.leftnumber != '' && props.rightnumber != '' ?
                    (
                        <button onClick={() => {
                            if (props.leftnumber != '' && props.rightnumber != '') {
                                if (props.leftnumber === props.rightnumber) {
                                    swal({
                                        text: "Duplicate number found",
                                        icon: "info",
                                        timer: 2000,
                                        button: false,
                                    }).then(()=>{
                                        props.setLeftNumber('')
                                        props.setRightNumber('')
                                    });
                                    return;
                                }
                                setVisible(true)
                            }
                        }} style={{ width: 80, height: 80, borderRadius: 100, border: "1px solid #ddd", backgroundColor: "#68be9f", color: "#fff", fontWeight: "bolder", fontSize: 12 }}>ADD BET</button>
                    )
                    :
                    (
                        <button style={{ width: 80, height: 80, borderRadius: 100, border: "1px solid #ddd", fontWeight: "bolder", fontSize: 12, opacity: 0.5 }}>ADD BET</button>
                    )
            }


        </>
    );
}

export default BetAmount;
