import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Account = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [user, setUser] = useState({})
    const [fetchuser, setFetchUser] = useState([])
    const [wallet, setWallet] = useState({ "balance": "0.00" })

    useMemo(async () => {
        var formData = new FormData();
        formData.append("userInfo", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data[0])
            setUser(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setWallet(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setFetchUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])
    
    const Logout = () => {
        sessionStorage.removeItem('user_id')
        window.location.assign('/')
    }

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header wallet={wallet} titlepage="Account" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ backgroundImage: `url(${require('./includes/sball.png')})` }}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* <section className="content-header"> */}
                        {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div> */}
                        {/* </section> */}

                        <div className="row">
                            <div className="col-lg-3 col-12">
                                {/* <div className="small-box bg-info">
                                    <div className="">
                                        <div style={{fontWeight: "bolder", fontSize: 35}}>STL</div>
                                        <div style={{fontWeight: "bolder", fontSize: 20}}>00-00</div>
                                        <div>Reward: 0.00</div>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-shopping-cart" />
                                    </div>
                                    <a href="/#" className="small-box-footer">
                                        Claim <i className="fas fa-arrow-circle-right" />
                                    </a>
                                </div> */}
                            </div>
                        </div>


                        {/* /.row (main row) */}
                    </div>

                    <div className="row">
                        {/* Left col */}
                        {/* /.Left col */}
                        {/* right col (We are only adding the ID to make the widgets sortable)*/}
                        <div className='col-sm-12'>
                            <div className="card card-widget widget-user">
                                <div className="widget-user-header bg-info">
                                    <h3 className="widget-user-username">{user?.firstname}</h3>
                                    <h5 className="widget-user-desc">
                                        {
                                            user?.account_type === 'general coordinator' ?
                                                <>General Coordinator</>
                                                :
                                                (
                                                    user?.account_type === 'coordinator' ?
                                                        <>Coordinator</>
                                                        :
                                                        (
                                                            user?.account_type === 'agent' ?
                                                                <>Agent</>
                                                                :
                                                                <>Player</>
                                                        )
                                                )
                                        }
                                    </h5>
                                </div>
                                <div className="widget-user-image">
                                    <img className="img-circle elevation-2" src={require('./includes/437803647_1158427041842714_2812090053231683581_n.jpg')} alt="User Avatar" />
                                </div>
                                <div className="card-footer">
                                    {
                                        // user?.account_type !== 'player'? 
                                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                            <span style={{fontWeight: "bolder", fontSize: 18}}>ID: {user?.user_id}</span>
                                            {/* <div className="float-right badge bg-success">{ENV.DOMAIN +'register/'+ user?.user_id}</div> */}
                                        </div>
                                        // :null
                                    }
                                    <div className="row">
                                        <div className="col-sm-4 border-right">
                                            <div className="description-block">
                                                <h5 className="description-header">
                                                    {
                                                        [...fetchuser].filter((i)=>{
                                                            return i.sponsor_id === user?.user_id
                                                        }).length
                                                    }
                                                </h5>
                                                <span className="description-text">FOLLOWERS</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="card card-widget widget-user-2" style={{marginBottom: 50}}>
                                {/* <div className="widget-user-header bg-warning">
                                    <div className="widget-user-image">
                                        <img className="img-circle elevation-2" src="../dist/img/user7-128x128.jpg" alt="User Avatar" />
                                    </div>
                                    <h3 className="widget-user-username">{user?.firstname}</h3>
                                    <h6 className="widget-user-desc">ID: {user?.user_id}</h6>
                                    <h6 className="widget-user-desc">
                                        {
                                            user?.account_type === 'general coordinator' ?
                                                <>General Coordinator</>
                                                :
                                                (
                                                    user?.account_type === 'coordinator' ?
                                                        <>Coordinator</>
                                                        :
                                                        (
                                                            user?.account_type === 'agent' ?
                                                                <>Agent</>
                                                                :
                                                                <>Player</>
                                                        )
                                                )
                                        }
                                    </h6>
                                    <span className="float-right badge bg-success">{ENV.DOMAIN + user?.user_id}</span>
                                </div> */}
                                <div className="card-footer p-0">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <a href="/info" className="nav-link">
                                                Information
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/wallet" className="nav-link">
                                                Balance <span className="float-right badge bg-success"><GetBalance wallet={wallet} /></span>
                                            </a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a href="/result" className="nav-link">
                                                Result
                                            </a>
                                        </li> */}
                                        {
                                            user?.admin === "1" ?
                                                <li className="nav-item" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    <a href="/administrator" className="nav-link">
                                                        Administrator
                                                    </a>
                                                    <span style={{ padding: 5 }}>Allowed</span>
                                                </li>
                                                : null
                                        }
                                        <li className="nav-item">
                                            <a href="#" onClick={() => Logout()} className="nav-link" style={{ color: "red" }}>
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{ position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", flexShrink: 1 }}>
                        <div onClick={() => window.location.assign("/dashboard")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Home</div>
                        <div style={{ height: 30, width: 1, backgroundColor: "#ddd" }}></div>
                        <div onClick={() => window.location.assign("/lottery")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Play</div>
                        <div style={{ height: 30, width: 1, backgroundColor: "#ddd" }}></div>
                        <div style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Account;
