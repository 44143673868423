import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import ChangePassword from './component/account/ChangePassword';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Info = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [user, setUser] = useState({})
    const [fetchuser, setFetchUser] = useState([])
    const [wallet, setWallet] = useState({ "balance": "0.00" })

    useMemo(async () => {
        var formData = new FormData();
        formData.append("userInfo", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data[0])
            setUser(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setWallet(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setFetchUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])


    // useEffect(()=>{ // Time
    //     const myInterval = setInterval(()=>{
    //         setSec(sec+1);
    //         if (sec === 59) {
    //             setMin(min+1)
    //             setSec(0);
    //         }
    //         if (min === 59) {
    //             setHr(hr+1)
    //             setMin(0)
    //             setSec(0);
    //         }
    //             setCount(count-1)
    //     }, 1000);
    //     console.log(hr+' : '+min+' : '+sec);
    //     return () => clearInterval(myInterval)
    // }, [count]);


    // console.log(window.innerWidth <= 768)
    // function domReady(fn) {
    //     if (document.readyState === "complete" || document.readyState === "interactive") {
    //         setTimeout(fn, 1);
    //     } else {
    //         document.addEventListener("DOMContentLoaded", fn);
    //     }
    // }

    // domReady(function () {
    //     var myqr = document.getElementById("you-qr-result");
    //     var lastResult, countResults = 0;
    //     function onScanSuccess(decodeText, decodeResult) {
    //         if (decodeText !== lastResult) {
    //             ++countResults;
    //             lastResult = decodeText;

    //             alert("you Qr is: " + decodeText, decodeResult);
    //             myqr.innerHTML = `you scan ${countResults} : ${decodeText}`;

    //         }
    //     }
    //     var htmlscanner = new Html5QrcodeScanner("preview", { fps: 10, qrbox: 250 })

    //     htmlscanner.render(onScanSuccess)
    // })


    const Logout = () => {
        sessionStorage.removeItem('user_id')
        window.location.assign('/')
    }

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header wallet={wallet} titlepage="Account" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ backgroundImage: `url(${require('./includes/sball.png')})` }}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* <section className="content-header"> */}
                        {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div> */}
                        {/* </section> */}

                        <div className="row">
                            <div className="col-lg-3 col-12">
                                {/* <div className="small-box bg-info">
                                    <div className="">
                                        <div style={{fontWeight: "bolder", fontSize: 35}}>STL</div>
                                        <div style={{fontWeight: "bolder", fontSize: 20}}>00-00</div>
                                        <div>Reward: 0.00</div>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-shopping-cart" />
                                    </div>
                                    <a href="/#" className="small-box-footer">
                                        Claim <i className="fas fa-arrow-circle-right" />
                                    </a>
                                </div> */}
                            </div>
                        </div>


                        {/* /.row (main row) */}
                    </div>

                    <div className="row">
                        {/* Left col */}
                        {/* /.Left col */}
                        {/* right col (We are only adding the ID to make the widgets sortable)*/}
                        <div className='col-sm-12'>



                            <div className="card card-widget widget-user-2">
                                {/* <div className="widget-user-header bg-warning">
                                    <div className="widget-user-image">
                                        <img className="img-circle elevation-2" src="../dist/img/user7-128x128.jpg" alt="User Avatar" />
                                    </div>
                                    <h3 className="widget-user-username">{user?.firstname}</h3>
                                    <h6 className="widget-user-desc">ID: {user?.user_id}</h6>
                                    <h6 className="widget-user-desc">
                                        {
                                            user?.account_type === 'general coordinator' ?
                                                <>General Coordinator</>
                                                :
                                                (
                                                    user?.account_type === 'coordinator' ?
                                                        <>Coordinator</>
                                                        :
                                                        (
                                                            user?.account_type === 'agent' ?
                                                                <>Agent</>
                                                                :
                                                                <>Player</>
                                                        )
                                                )
                                        }
                                    </h6>
                                    <span className="float-right badge bg-success">{ENV.DOMAIN + user?.user_id}</span>
                                </div> */}
                                <div className="card-footer p-0">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <a className="nav-link" style={{color: "#000"}}>
                                                Account <span className="float-right">{user.user_id}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" style={{color: "#000"}}>
                                                Username <span className='float-right'>{user.username}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" style={{color: "#000"}}>
                                                Fullname <span className='float-right'>{user.firstname}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" style={{color: "#000"}}>
                                                Account Type <span className='float-right'>{
                                                        user?.account_type === 'general coordinator' ?
                                                            <>General Coordinator</>
                                                            :
                                                            (
                                                                user?.account_type === 'coordinator' ?
                                                                    <>Coordinator</>
                                                                    :
                                                                    (
                                                                        user?.account_type === 'agent' ?
                                                                        <>Agent</>
                                                                        :
                                                                        <>Player</>
                                                                    )
                                                            )
                                                    }</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" style={{color: "#000"}}>
                                                Contact No. <span className='float-right'>{user.contact_no}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <ChangePassword user={user} />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{ position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", flexShrink: 1 }}>
                        <div onClick={() => window.location.assign("/dashboard")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Home</div>
                        <div style={{ height: 30, width: 1, backgroundColor: "#ddd" }}></div>
                        <div onClick={() => window.location.assign("/lottery")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Play</div>
                        <div style={{ height: 30, width: 1, backgroundColor: "#ddd" }}></div>
                        <div onClick={() => window.location.assign("/account")} style={{ width: "33.33%", textAlign: "center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Info;
