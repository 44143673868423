import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../ENV';

const Navbar = (props) => {
    const position = sessionStorage.getItem('position');


    const [user, setUser] = useState({})

    useMemo(async () => {
        var formData = new FormData();
        formData.append("userInfo", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data[0])
            setUser(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    const Logout = () => {
        sessionStorage.removeItem('user_id')
        window.location.assign('/')
    }
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} /> */}
                {/* <span className="brand-text font-weight-light">Clinic</span> */}
                <span className="brand-text font-weight-light">SUREBALL</span>
            </a>
            {/* Sidebar */}
            <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition"><div className="os-resize-observer-host observed"><div className="os-resize-observer" style={{ left: 0, right: 'auto' }} /></div><div className="os-size-auto-observer observed" style={{ height: 'calc(100% + 1px)', float: 'left' }}><div className="os-resize-observer" /></div><div className="os-content-glue" style={{ margin: '0px -8px', width: 249, height: 528 }} /><div className="os-padding"><div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}><div className="os-content" style={{ padding: '0px 8px', height: '100%', width: '100%' }}>
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src={require('../includes/437803647_1158427041842714_2812090053231683581_n.jpg')} className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        {/* <a className="d-block">Clinic</a> */}
                        <a className="d-block">{props.titlestatus !== '' ? props.titlestatus : user.firstname}</a>
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    {
                        props.titlestatus === 'Administrator' ?
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library */}
                                <li className="nav-item">
                                    <a href="/administrator" className="nav-link">
                                        <p>Home</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/result" className="nav-link">
                                        <p>Results</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/member" className="nav-link">
                                        <p>Members</p>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a href="/administrator/credit/code" className="nav-link">
                                        <p>Credit Code</p>
                                    </a>
                                </li> */}
                                <li className="nav-item">
                                    <a href="/administrator/tickets" className="nav-link">
                                        <p>Tickets</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/coins" className="nav-link">
                                        <p>Coins</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/payin/request" className="nav-link">
                                        <p>Cashin Request</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/payout/request" className="nav-link">
                                        <p>Payout Request</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/statistics" className="nav-link">
                                        <p>Statistics</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/report/payout" className="nav-link">
                                        <p>Payout Report</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/report/deposite" className="nav-link">
                                        <p>Deposit Report</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/manage/lottery" className="nav-link">
                                        <p>Manage Start / Stop</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={() => Logout()} className="nav-link">
                                        <p>Logout</p>
                                    </a>
                                </li>
                            </ul>
                            :
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library */}
                                <li className="nav-item">
                                    <a href="/dashboard" className="nav-link">
                                        <p>Home</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/lottery" className="nav-link">
                                        <p>Play</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/wallet" className="nav-link">
                                        <p>Wallet</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/result" className="nav-link">
                                        <p>Result</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/member" className="nav-link">
                                        Member
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/reward" className="nav-link">
                                        Reward
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/commission" className="nav-link">
                                        Commission
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/transactions" className="nav-link">
                                        Transactions
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/account" className="nav-link">
                                        <p>Account</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={() => Logout()} className="nav-link">
                                        <p style={{color: "red"}}>Logout</p>
                                    </a>
                                </li>
                            </ul>
                    }
                </nav>
                {/* /.sidebar-menu */}
            </div></div></div><div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ width: '100%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ height: '38.9257%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar-corner" /></div>
            {/* /.sidebar */}
        </aside>

    );
}

export default Navbar;
