// import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useState } from 'react';
import ENV from '../../ENV';
import swal from 'sweetalert';

const EnterResult = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({leftnumber: '', rightnumber: ''})




    // console.log(inputvalue)
    const hideDialog = (event) => {
        // alert("")
        // console.log(event)
        setOpenDialog(prev => ({ ...prev, open: false }))
        setInputValue({leftnumber: '', rightnumber: ''})
    }

    const submitbtn = async () => {
        if (inputvalue?.leftnumber !== '' && inputvalue?.rightnumber !== '') {
            if (inputvalue?.leftnumber !== inputvalue?.rightnumber) {
                var formData = new FormData();
                formData.append("send_result", 1);
                formData.append("number", `${inputvalue?.leftnumber}-${inputvalue?.rightnumber}`);
                formData.append("drawtime", props.drawtime + ' ' + props.meridiem);
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'api/process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data)
                    setOpenDialog(prev => ({ ...prev,  open: false }))
                })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {

            }
        } else {

        }
    }

    useEffect(async()=>{
        if (inputvalue?.leftnumber !== '' && inputvalue?.rightnumber !== '') {
            if (inputvalue?.leftnumber === inputvalue?.rightnumber) {
                // alert(`${inputvalue?.leftnumber}-${inputvalue?.rightnumber}`)
                setInputValue({leftnumber: '', rightnumber: ''})
            }
        }
    }, [inputvalue])

    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>Enter STL Result</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 500, fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>


                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div>Draw Time</div>
                        <div style={{ fontSize: 25, fontWeight: "bolder" }}>{props.drawtime+' '+props.meridiem}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                        <select onChange={(e)=>setInputValue(prev=>({...prev, 'leftnumber': e.target.value}))} value={inputvalue.leftnumber} style={{ width: 100, height: 50, borderRadius: 5, border: "1px solid #ddd", fontSize: 30, textAlign: "center" }}>
                            <option></option>
                            {
                                [...Array(38).keys()].map(n => {
                                    const number = n + 1;
                                    return (
                                        <option>{number}</option>
                                    )
                                })
                            }
                        </select>
                        {/* <input type='number' onChange={(e)=>setInputValue(prev=>({...prev, 'leftnumber': e.target.value}))} value={inputvalue.leftnumber} style={{width: 100, height: 50, borderRadius: 5, border:"1px solid #ddd", fontSize: 45, textAlign: "center"}} /> */}
                        <span style={{ fontSize: 35 }}>-</span>
                        <select onChange={(e)=>setInputValue(prev=>({...prev, 'rightnumber': e.target.value}))} value={inputvalue.rightnumber} style={{ width: 100, height: 50, borderRadius: 5, border: "1px solid #ddd", fontSize: 30, textAlign: "center" }}>
                            <option></option>s
                            {
                                [...Array(38).keys()].map(n => {
                                    const number = n + 1;
                                    return (
                                        <option>{number}</option>
                                    )
                                })
                            }
                        </select>
                        {/* <input type='number' onChange={(e)=>setInputValue(prev=>({...prev, 'rightnumber': e.target.value}))} value={inputvalue.rightnumber} style={{width: 100, height: 50, borderRadius: 5, border:"1px solid #ddd", fontSize: 45, textAlign: "center"}} /> */}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        {
                            inputvalue?.leftnumber === inputvalue?.rightnumber ?
                                <button style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd" }}>Submit Result</button>
                                :
                                (
                                    inputvalue?.leftnumber !== '' && inputvalue?.rightnumber !== '' ?
                                        <button onClick={() => submitbtn()} style={{ width: 200, height: 40, borderRadius: 5, backgroundColor: "green", color: "#fff", border: "1px solid #ddd" }}>Submit Result</button>
                                        :
                                        <button style={{ width: 200, height: 40, borderRadius: 5, border: "1px solid #ddd" }}>Submit Result</button>
                                )
                        }
                    </div>
                </div>

                <div style={{overflowX: "auto", height: "100%"}}>
                    {
                        [...props.ticketcount].filter((i)=>i.time === props.drawtime).map((item)=>{
                            return (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", boxShadow: "0px 1px 2px grey", padding: 5}}>
                                    <span>{item.number}</span>
                                    <span>{item.amount}</span>
                                </div>
                            )
                        })
                    }
                    {/* {JSON.stringify([...props.ticketcount])} */}
                </div>

            </Sidebar>

            <span onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} className='btn btn-warning btn-xs'>Enter Result</span>
            {/* <button onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{ width: 100 }} className='btn btn-info btn-sm'>Payout</button> */}
        </>
    );
}

export default EnterResult;
