import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import MamberPanel from './component/member/MamberPanel';
import CreditCodeCard from './component/creditcode/CreditCodeCard';
import CreditAddBtn from './component/creditcode/CreditAddBtn';
import CanvasJSReact from '@canvasjs/react-charts';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const AdminStatistics = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [creditlist, setCreditList] = useState([])
    const [search, setSearch] = useState('')
    const [wallet, setWallet] = useState({"balance": "0.00"})
    const [monthlydata, setMonthlyData] = useState([])
    const [dailydata, setDailyData] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("daily_report", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setDailyData(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("monthly_report", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setMonthlyData(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])
    
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const DailyData = {
        // theme: "light2", // "light1", "light2", "dark1"
        // text: "Top 10 Most Viewed YouTube Videos",
        title: {
            fontSize: 15,
            text: "Daily Report"
        },
        axisX: {
            margin: 10,
            labelPlacement: "inside",
            tickPlacement: "inside"
        },
        axisY: {
            title: "(in PHP)",
            prefix: "₱"
        },
        data: [{
            type: "bar",
            yValueFormatString: "₱##,###.##",
            dataPoints: [...dailydata]
        }]
    }

    const MonthlyData = {
        // theme: "light2", // "light1", "light2", "dark1"
        // text: "Top 10 Most Viewed YouTube Videos",
        title: {
            fontSize: 15,
            text: "Monthly Report"
        },
        axisX: {
            margin: 10,
            labelPlacement: "inside",
            tickPlacement: "inside"
        },
        axisY: {
            title: "(in PHP)",
            prefix: "₱"
        },
        data: [{
            type: "column",
            yValueFormatString: "₱##,###.##",
            dataPoints: [...monthlydata]
        }]
    }

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header hidebalance={true} />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} titlestatus="Administrator" />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{backgroundImage: `url(${require('./includes/sball.png')})`}}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <CanvasJSChart options={DailyData} containerProps={{ width: '100%', height: '300px' }} />
                        <hr />
                        <CanvasJSChart options={MonthlyData} containerProps={{ width: '100%', height: '300px' }} />
                    </div>                    
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default AdminStatistics;
