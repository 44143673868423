import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import './payout.css';
import axios from 'axios';
import ENV from '../../ENV';

const PayoutPanelInfo = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({...props?.item})
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    // console.log(inputvalue)

    const ApprovePayoutBtn = async (status) => {
        var formData = new FormData();
        formData.append("updatePayout", 1);
        formData.append("status", status);
        formData.append("inputvalue", JSON.stringify(inputvalue));        
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            props.setPayoutList([...res.data])
            hideDialog()
        });
    }

    const DeclinedPayoutBtn = async (status) => {
        var formData = new FormData();
        formData.append("updatePayout", 1);
        formData.append("status", status);
        formData.append("inputvalue", JSON.stringify(inputvalue));        
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            props.setPayoutList([...res.data])
            hideDialog()
        });
    }


    return (
        <>
        <Sidebar
            icons={
                <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>{props?.item.firstname}</div>
            }
            blockScroll={true}
            position='bottom'
            draggable={false}
            style={{ width: '100rem', height: 500, fontSize: 15 }} //, height: 500
            visible={openDialog.open}
            onHide={hideDialog}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div>
                        <div>
                            <span>Account Name.: <span style={{fontWeight: "bolder"}}>{props?.item?.account_name}</span></span>
                        </div>
                        <div>
                            <span>Account No.: <span style={{fontWeight: "bolder"}}>{props?.item?.account_number}</span></span>
                        </div>
                        <div>
                            <span>Amount: <span style={{fontWeight: "bolder"}}>{Number(props.item?.amount-props.item?.fee)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                        </div>
                        <div>
                            <span>Fee: <span style={{fontWeight: "bolder"}}>{Number(props.item?.fee)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                        </div>
                        <div>
                            <span>Total Payout: <span style={{fontWeight: "bolder"}}>{Number(props.item?.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                        </div>
                        <div>
                            <span>Method: <span style={{fontWeight: "bolder"}}>{props?.item?.method}</span></span>
                        </div>
                        <div>
                            <span>Date Request: <span style={{fontWeight: "bolder"}}>{props?.item?.date_time}</span></span>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5}}>
                        <span>Ref:</span>
                        <input type='text' name={"reference_no"} onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{width: 200, height: 35, borderRadius: 5, border: "1px solid #ddd", padding: 10   }} />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center"}}>
                        <button onClick={()=>ApprovePayoutBtn('approved')} style={{width: 150, borderRadius: 5, height: 40, border: "1px solid #ddd", backgroundColor: "rgb(98, 175, 215)", color: "#fff", fontWeight: "bolder"}}>Approve</button>
                        <button onClick={()=>DeclinedPayoutBtn('declined')} style={{width: 150, borderRadius: 5, height: 40, border: "1px solid #ddd", backgroundColor: "rgb(211 111 25)", color: "#fff", fontWeight: "bolder"}}>Decline</button>
                    </div>
                </div>
            </Sidebar>
            {/* {JSON.stringify(props.item)} */}
            <div onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{ padding: 0, width: 40, textAlign: "center", borderRadius: 5, border: "1px solid #ddd" }}>
                <i className='pi pi-list' />
            </div>
        </>
    );
}

export default PayoutPanelInfo;
