import React from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

const AdminBetPlay = () => {
    const { user_id, draw_time } = useParams();
    return (
        <div>
            {draw_time}
        </div>
    );
}

export default AdminBetPlay;
