import React from 'react';

const CoinsCard = (props) => {
    return (
        <>

            <div key={props.key} style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ fontWeight: "normal", fontSize: 12 }}>Transferred At: {props.item?.date_time}</span>
                    <span style={{ fontWeight: "normal" }}></span>
                </div>
                <span style={{ fontWeight: "normal" }}>ID: <span style={{ fontWeight: "bolder" }}>{props.item.user_id}</span></span>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ fontWeight: "normal" }}>Name: <span style={{ fontWeight: "bolder" }}>{[...props.user].filter((i)=>i.user_id === props.item.user_id)[0]?.firstname}</span></span>
                    <span style={{ fontWeight: "normal" }}>Php {Number(props.item?.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            </div>
        </>
    );
}

export default CoinsCard;
