import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Transactions = (props) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';
    const [date, setDate] = useState(null);

    const [wallet, setWallet] = useState({"balance": "0.00"})
    const [payoutlist, setPayoutList] = useState([])
    const [orderlist, setOrderList] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])



    useMemo(async()=> {
        var formData = new FormData();
        formData.append("payoutlist", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setPayoutList(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("orderlist", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setOrderList(res.data)
            console.log(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header wallet={wallet} titlepage="Transactions" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{backgroundImage: `url(${require('./includes/sball.png')})`}}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    {/* <div className="container-fluid">
                        <section className="content-header"> */}
                            {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div> */}
                        {/* </section>
                    </div> */}

                    <div className="row">
                        <div style={{width: "100%", padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            {/* <span style={{fontWeight: "bolder"}}>Transactions</span> */}
                            {/* <Calendar value={date} onChange={(e) => setDate(e.value)} dateFormat="yy-mm-dd" style={{height: 35, width: 120}} /> */}
                        </div>
                        <div style={{display: 'flex', flexDirection: "column", gap: 5, width: "100%", padding: 5}}>
                            
                            {

                                Object.entries([...orderlist]?.reduce((groups, item) => {
                                    const { time_draw } = item;
                                    if (!groups[time_draw]) {
                                        groups[time_draw] = [];
                                    }
                                    groups[time_draw].push(item);
                                    return groups;
                                }, {})).map(([time_draw, items]) => {
                                    return (

                                        <div style={{ padding: 10, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", boxShadow: "0px 1px 2px grey" }}>
                                            <div style={{ fontWeight: "bolder", fontSize: 20 }}>Draw: {time_draw === '10:45 AM' ? '11:00 AM' : time_draw === '02:45 PM' ? '03:00 PM' : '07:00 PM'}</div>
                                            <div style={{ fontWeight: "bolder" }}>Game: STL-SILAY</div>
                                            <div style={{ fontWeight: "bolder" }}>Date: {format(new Date(items[0].date_time), 'MMMM dd, yyyy')}</div>
                                            <span style={{ fontSize: 20, fontWeight: "bolder" }}>Total: {[...items[0]?.list]?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                            <div style={{ border: "1px solid #ddd", width: "100%" }}></div>
                                            <div>
                                                {
                                                    [...items[0]?.list].map((item) => {
                                                        return (
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div>Ticket: <span style={{ fontWeight: "bolder" }}>{item?.number}</span></div>
                                                                <div>{Number(item?.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                            
                            
                            
                            
                            
                            
                            {/* {
                                [...payoutlist].filter((i) => {
                                    if (date !== null) {
                                        return i.user_id === sessionStorage.getItem('user_id') && i.status === 'approved' && i?.date_time.toLowerCase().includes(format(date !== null ? date : new Date(), 'yyyy-MM-dd')?.toLowerCase())
                                    }
                                    return i.user_id === sessionStorage.getItem('user_id') && i.status === 'approved'
                                }).map((item) => {
                                    return (
                                        <div style={{ display: "flex", flexDirection: "column", gap: 0, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                {
                                                    item.status === 'topup' ?
                                                        <span>Credit</span>
                                                        :
                                                        <span>Method: Bank</span>
                                                }
                                                {
                                                    item.status === 'topup' ?
                                                        <span style={{ fontSize: 12, color: "orange" }}>Topup</span>
                                                        :
                                                        (
                                                            item.status === 'pending' ?
                                                                <span style={{ fontSize: 12, color: "red" }}>Pending Payout</span>
                                                                :
                                                                (
                                                                    item.status === 'approved' ?
                                                                        <span style={{ fontSize: 12, color: "green" }}>Approved</span>
                                                                        :
                                                                        (
                                                                            item.status === 'cancelled' ?
                                                                                <span style={{ fontSize: 12, color: "red" }}>Cancelled</span>
                                                                                :
                                                                                (
                                                                                    item.status === 'declined' ?
                                                                                        <span style={{ fontSize: 12, color: "red" }}>Declined</span>
                                                                                        :
                                                                                        <span style={{ fontSize: 12, color: "red" }}>Payout</span>
                                                                                )
                                                                        )
                                                                )
                                                        )
                                                }
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                <span>Amount: {Number(item?.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                            </div>
                                            <span style={{ fontSize: 12 }}>{item?.date_time}</span>
                                        </div>
                                    )
                                })
                            } */}
                        </div>
                    </div>

                    
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Transactions;

