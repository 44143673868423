import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';

const CreditCodeCard = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({"qty": 0})
    // console.log(inputvalue)
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const disableCredit = async () => {
        if (inputvalue.qty !== 0 && inputvalue.amount !== 0) {
            var formData = new FormData();
            formData.append("disablecredit", 1);
            formData.append("id", props.item.id);
            await axios({
                method: "post",
                url: ENV.DOMAIN+'api/process.php',
                data: formData,
            }).then((res)=>{
                if (res.data.length>0) {
                    if (res.data[0].invalid === 'closed') {
                        swal({
                            title: "Invalid!",
                            text: "Invalid Cancelled it's Already Used!",
                            icon: "error",
                            timer: 2000,
                            button: false,
                        });
                    } else {
                        setOpenDialog(prev => ({ ...prev, open: false }))
                        props.setCreditList([...res.data])
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
        } else {

        }
    }
    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>
                        Details
                    </div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 200, fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>
                <div>
                    <div style={{display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{fontWeight: "normal", fontSize: 20}}>Code: <span style={{textDecoration: "underline", fontWeight: "bolder"}}>{props.item?.code}</span></div>
                        <div style={{fontWeight: "normal", fontSize: 15}}>Amount: <span style={{textDecoration: "underline", fontWeight: "bolder"}}>{Number(props.item?.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                        {
                            props.statuscode === 'open' ?
                            <button onClick={()=>disableCredit()} style={{borderRadius: 5, fontWeight: "bolder", height: 45, backgroundColor: "#eab308", border: "1px solid #ddd", padding: "0px 15px 0px 15px"}}>Disable this Code</button>
                            : 
                            <>
                                <span style={{fontWeight: "bolder"}}>Used By</span>
                                <span style={{fontWeight: "normal", textDecoration: "underline"}}>{[...props.user].filter((i)=>i.user_id === props.item?.user_id)[0]?.firstname}</span>
                                <span style={{fontWeight: "normal", textDecoration: "underline"}}>{props.item?.date_time}</span>
                            </>
                        }
                    </div>
                </div>
            </Sidebar>                                     
            <div onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))} key={props.key} style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: props.statuscode === 'close' ? "rgb(185 185 185)" : "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ fontWeight: "normal", fontSize: 12 }}>Created At: {props.item?.date_created}</span>
                    <span style={{ fontWeight: "normal" }}></span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ fontWeight: "normal" }}>Code: <span style={{ fontWeight: "bolder" }}>{props.item?.code}</span></span>
                    <span style={{ fontWeight: "normal" }}>Php {Number(props.item?.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            </div>
        </>
    );
}

export default CreditCodeCard;
