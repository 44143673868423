import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import MamberPanel from './component/member/MamberPanel';
import PayoutPanelInfo from './component/payout/PayoutPanelInfo';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const AdminPayoutRequest = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [user, setUser] = useState([])
    const [search, setSearch] = useState('')
    const [wallet, setWallet] = useState({"balance": "0.00"})
    const [payoutlist, setPayoutList] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])


    useMemo(async()=> {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])


    useMemo(async()=> {
        var formData = new FormData();
        formData.append("payoutlist", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setPayoutList(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header hidebalance={true} />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} titlestatus="Administrator" />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{backgroundImage: `url(${require('./includes/sball.png')})`}}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">

                    <div className="row">
                        <div style={{padding: 5, width: "100%"}}>
                            <span style={{fontWeight: "bolder", fontSize: 20}}>Request Payout</span>
                            
                            {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, width: "100%" }}>
                                <div style={{ border: "1px solid #009688", borderRadius: 6, display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ width: 40, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: "5px 0px 0px 5px", backgroundColor: " #fff" }}>
                                        <span className='pi pi-search' style={{fontSize: 20, color: "#a3a3a3"}} ></span>
                                    </div>
                                    <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", width: "100%", flexGrow: 1, borderTopLeftRadius: 0, outline: "none", borderBottomLeftRadius: 0, height: "100%", padding: 5, border: "none", backgroundColor: " #fff" }} placeholder='Search a Member name or Account ID' />
                                    <button outlined={false} style={{ width: 50, height: 45, display: "flex", outline: "none", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#009688", border: "1px solid #009688", borderRadius: "0px 5px 5px 0px" }}>
                                        <i className='pi pi-align-justify' style={{color: "#fff"}}/>
                                    </button>
                                </div>
                            </div> */}
                        </div>
                        <div style={{display: 'flex', flexDirection: "column", gap: 5, width: "100%", padding: 5}}>
                            {
                                [...payoutlist].filter((i)=>i.status === 'pending').map((item, key)=>{
                                    return (                                        
                                        <div key={key} style={{display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey"}}>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                <span style={{fontWeight: "bolder"}}>{item?.account_name}</span>
                                                <span>
                                                    {
                                                        item.date_time
                                                    }
                                                </span>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                                    <span>Account No.: {item?.account_number}</span>
                                                    <span>ID: {item?.user_id}</span>
                                                    <span>Amount: <span style={{fontWeight: "bolder"}}>{Number(item?.amount-item?.fee)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                                    <span>Fee: {Number(item?.fee)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                    <span>Total Payout: <span style={{fontWeight: "normal"}}>{Number(item?.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                                                    <span>Method: {item?.method}</span>
                                                </div>
                                                <PayoutPanelInfo item={item} setPayoutList={setPayoutList} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default AdminPayoutRequest;
