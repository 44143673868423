import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import swal from 'sweetalert';
import { format } from 'date-fns';

import moment from 'moment';
import axios from 'axios';
import ENV from '../../ENV';
// import './member.css';

const ChangePassword = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({user_id: sessionStorage.getItem('user_id'), current_password:"", new_password:"", confirm_password:""})
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const openDialogBtn = () => {
        setOpenDialog(prev => ({ ...prev, "open": true }))
        setInputValue({user_id: sessionStorage.getItem('user_id'), current_password:"", new_password:"", confirm_password:""})
    }

    const savePasswordBtn = async () => {
        
        if (inputvalue.current_password === '' && inputvalue.new_password === '' && inputvalue.confirm_password === '') {            
            swal({
                text: "please complete the form!",
                icon: "error",
                timer: 1000,
                button: false,
            });
            return;
        }

        var formData = new FormData();
        formData.append("update_password", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            if (res.data[0].status === 'incorrect_current_password') {
                swal({
                    title: "Please try again!",
                    text: "Invalid Old Password",
                    icon: "error",
                    timer: 2000,
                    button: false,
                });
            } else if (res.data[0].status === 'not_match_new_password') {
                swal({
                    title: "Please try again!",
                    text: "Password not match!",
                    icon: "error",
                    timer: 2000,
                    button: false,
                });
            } else if (res.data[0].status === 'success') {
                swal({
                    text: "Successfuly Updated!",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(()=>{
                    setOpenDialog(prev => ({ ...prev, open: false }))
                });
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
        <Sidebar
            icons={
                <div style={{textAlign: "center", width: "100%"}}>Change Password</div>
            }
            blockScroll={true}
            position='bottom'
            draggable={false}
            showCloseIcon={false}
            style={{ width: '100rem', height: 400, fontSize: 15, padding: 0 }} //, height: 500
            visible={openDialog.open}
            onHide={hideDialog}>
                
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div>
                        <div>
                            <label>Current Password</label>
                            <input name='current_password' onChange={(e)=> setInputValue(prev=>({...prev, [e.target.name]: e.target.value }))} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 5}} />
                        </div>
                        <div>
                            <label>New Password</label>
                            <input name='new_password' onChange={(e)=> setInputValue(prev=>({...prev, [e.target.name]: e.target.value }))} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 5}} />
                        </div>
                        <div>
                            <label>Confirm Password</label>
                            <input name='confirm_password' onChange={(e)=> setInputValue(prev=>({...prev, [e.target.name]: e.target.value }))} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 5}} />
                        </div>
                    </div>
                    <div>
                        <button onClick={()=>savePasswordBtn()} style={{width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", backgroundColor:"#d77942", color: "#fff"}}>Save Change</button>
                    </div>
                </div>
            </Sidebar>
            {/* {JSON.stringify(props.user)} */}
            <a className="nav-link" style={{color: "#000"}}>
                Password <span className='float-right'> ***** <i onClick={() => openDialogBtn()} className='pi pi-pencil' /></span>
            </a>
            {/* <div onClick={() => openDialogBtn()} style={{ padding: 0, width: 40, textAlign: "center", borderRadius: 5, border: "1px solid #ddd" }}>
                <i className='pi pi-list' />
            </div> */}
        </>
    );
}

export default ChangePassword;
