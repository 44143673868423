import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useState } from 'react';
import TicketReview from './TicketReview';
import BetAmount from './BetAmount';

const Playbutton = (props) => {
    const [visible, setVisible] = useState(false);
    const [leftnumber, setLeftNumber] = useState("");
    const [rightnumber, setRightNumber] = useState("");
    const [currentTime, setCurrentTime] = useState("00:00");

    const onPick = (n) => {
        if (leftnumber == '') {
            setLeftNumber(n)
        } else {
            if (leftnumber != n) {                
                if (rightnumber == '') {
                    setRightNumber(n)
                }
            }
        }

    }

    const randomnumber = (n) => {
        return Math.floor(Math.random() * 38) + n;
    }

    const randomPick = () => {

        let random = randomnumber(1);
        setLeftNumber(random)  
        setTimeout(()=>{  
            let newrandom = randomnumber(1);
            setRightNumber(newrandom)
        }, 100) 
    }

    const onClear = () => {
        setLeftNumber('')
        setRightNumber('')
        // if (rightnumber != '') {
        //     setRightNumber('')
        // } else {
        //     if (leftnumber != '') {
        //         setLeftNumber('')
        //     }
        // }
    }

    useEffect(async()=> {
        // setInterval(()=>{
        //     setCurrentTime(new Date().toLocaleString()) 
        // }, 1000)
        
        if(leftnumber != '' && rightnumber != ''){
            if (leftnumber == rightnumber) {
                randomPick()
                // let newrandom = randomnumber(1);
                // setRightNumber(newrandom)
                // alert(leftnumber+' - '+rightnumber)
            }
        }
    }, [leftnumber, rightnumber])

    

    return (
        <>


            <Sidebar showCloseIcon={false} blockScroll={true} style={{ minHeight: 550, backgroundImage: `url(${require('../includes/sball.png')})` }} visible={visible} position="bottom" onHide={() => setVisible(false)}>
                <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                    <span style={{fontSize: 25, fontWeight: "bolder"}}>{props.item.drawtime}</span>
                    <span style={{color: "red"}}>Close: {props.item.time}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10, position: "relative", marginBottom: 20 }}>
                    <div style={{ position: "absolute", left: 15 }}>
                        <span onClick={() => randomPick()} style={{ color: "green", cursor: "pointer" }}>Random</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10 }}>
                        <div className='circle' style={{ width: 70, height: 70, borderRadius: 100, padding: 7, backgroundColor: "#000", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: 20, fontWeight: "bolder" }}>
                            {leftnumber != '' ? 
                                <div style={{border:"2px solid #ddd", backgroundColor:"#fff", borderRadius: 100, padding:5, width: 35, height: 35, color: "#000", display: "flex", justifyContent: "center", alignItems: "center"}}>{leftnumber}</div>
                            : null} 
                        </div>
                        <div className='circle' style={{ width: 70, height: 70, borderRadius: 100, padding: 7, backgroundColor: "#000", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: 20, fontWeight: "bolder" }}>
                            {rightnumber != '' ? 
                                <div style={{border:"2px solid #ddd", backgroundColor:"#fff", borderRadius: 100, padding:5, width: 35, height: 35, color: "#000", display: "flex", justifyContent: "center", alignItems: "center"}}>{rightnumber}</div>
                            : null} 
                        </div>
                    </div>
                    <div style={{ position: "absolute", right: 30 }}>
                        <span onClick={() => onClear()} style={{ color: "red", cursor: "pointer" }}>Clear</span>
                    </div>
                </div>
                <div style={{ overflowX: "scroll", height: 250 }} className='hidescroll'>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", alignItems: "center", gap: 10, padding: 10 }}>
                        {
                            [...Array(38).keys()].map((n) => {
                                const number = n + 1;
                                return (
                                    <div onClick={() => onPick(number)}
                                        style={{ width: 40, height: 40, cursor: "pointer", borderRadius: 100, border: "1px solid #ddd", position: "relative", display: "flex", backgroundColor: number == leftnumber ? "#000" : number == rightnumber ? "#000" : "", color: number == leftnumber ? "#fff" : number == rightnumber ? "#fff" : "#000", justifyContent: "center", alignItems: "center" }}
                                    >
                                        {
                                            number == leftnumber && number == rightnumber ? (
                                                leftnumber!='' && rightnumber!='' ? (
                                                    leftnumber == rightnumber ? 
                                                        <span style={{display: "flex", justifyContent:"center", alignItems:"center", fontSize: 10, position: "absolute", right: -7, top: -7, width: 20, height: 20, borderRadius: 100, zIndex: 999, color: "#fff", padding: 3, backgroundColor: "red"}}>{2}</span>
                                                    : null
                                                ) : null
                                            ) : null
                                        }
                                        {number}
                                    </div>
                                )
                            })
                        }
                        
                        {/* <div onClick={()=>randomPick()}
                        style={{ width: 40, height: 40, borderRadius: 100, border: "1px solid #ddd", position: "relative", display: "flex", backgroundColor: "gold", color: "#000", justifyContent: "center", alignItems: "center" }}>
                            LP                
                        </div> */}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: 100 }}>
                    {/* <TicketReview orderlistLoad={props.orderlistLoad} betnumberlist={props.betnumberlist} setBetNumberList={props.setBetNumberList} loadWallet={props.loadWallet} wallet={props.wallet} /> */}
                    <div></div>
                    <BetAmount item={props.item} leftnumber={leftnumber} setLeftNumber={setLeftNumber} rightnumber={rightnumber} setRightNumber={setRightNumber} betnumberlist={props.betnumberlist} setBetNumberList={props.setBetNumberList} loadWallet={props.loadWallet} wallet={props.wallet} orderlistLoad={props.orderlistLoad} />
                    {/* <button onClick={onPlay} style={{ width: 80, height: 80, borderRadius: 100, border: "1px solid #ddd", fontWeight: "bolder", fontSize: 12 }}>ADD BET</button> */}
                    <div style={{ position: "absolute", right: 40 }}>
                        <span onClick={() => {
                            setVisible(false)
                            setLeftNumber('')
                            setRightNumber('')
                        }} style={{ color: "red", fontWeight: "bolder", cursor: "pointer" }}>Close</span>
                    </div>
                </div>
            </Sidebar>




            <button onClick={() => {
                setVisible(true)
                setLeftNumber('')
                setRightNumber('')
            }} style={{ fontSizes: 9, borderRadius: 20, border: "1px solid #ddd" }}>Play Now</button>
        </>
    );
}

export default Playbutton;
