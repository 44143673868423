import React from 'react'
//stgcinc888@gmail.com

const SUBSCRIPTION = '';

const sockeROOM = '4M09';
const socketAPI = 'http://192.168.1.11:3001';
// const DOMAIN = 'https://tubser.stgc.online/';
// const DOMAIN = 'http://192.168.1.12/tubillara_clinic/';
// const DOMAIN = 'https://api_clinic.aetosmsi.com/tubillara_clinic/'; // cloudflare port forwarding
// const DOMAIN = 'http://192.168.1.201/';
// const DOMAIN = 'http://192.168.1.13/';
// const DOMAIN = 'https://clinic.stgc.tech/';
// const DOMAIN = 'https://tubillaraclinic.stgc.online/';
// const DOMAIN = 'http://192.168.1.25/sureball/';
// const DOMAIN = 'http://192.168.1.20/sureball/';
// const DOMAIN = 'http://localhost/sureball/';
// const DOMAIN = 'http://192.168.1.14/sureball/';
// const IMAGEDIR = 'http://192.168.1.14/sureball/images/';
const DOMAIN = 'https://sureball77.com/';
const IMAGEDIR = 'https://sureball77.com/images/';

const ENV =  {
    DOMAIN,
    IMAGEDIR,
    socketAPI,
    sockeROOM
}

export default ENV
