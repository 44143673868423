import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import MamberPanel from './component/member/MamberPanel';
import AdminResultList from './component/result/AdminResult/AdminResultList';
import TicketPanel from './component/ticket/TicketPanel';
import { format } from 'date-fns';
import './extra/css/AdminTickets.css'


// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const AdminManageLottery = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [tickets, setTickets] = useState([])
    const [resultwinner, setResultWinner] = useState([])
    const [search, setSearch] = useState('')
    const [wallet, setWallet] = useState({ "balance": "0.00" })
    const [user, setUser] = useState([])
    const [onoff, setOnOff] = useState([])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("onoffplayfetch", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setOnOff(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    const onoffplayBtn = async (draw_time) => {
        console.log(draw_time)
        var formData = new FormData();
        formData.append("onoffplayupdate", 1);
        formData.append("draw_time", draw_time);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setOnOff([...res.data])
        })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                <Header wallet={wallet} titlepage="Manage" hidebalance={true} />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} titlestatus="Administrator" />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ backgroundImage: `url(${require('./includes/sball.png')})` }}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* <section className="content-header"> */}
                        {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div> */}
                        {/* </section> */}




                        {/* /.row (main row) */}
                    </div>

                    <div className="row">
                        <div style={{ padding: 5, width: "100%" }}>
                            {/* <span style={{fontWeight: "bolder", fontSize: 20}}>Members</span> */}

                            {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, width: "100%" }}>
                                <div style={{ border: "1px solid #009688", borderRadius: 6, display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ width: 40, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: "5px 0px 0px 5px", backgroundColor: " #fff" }}>
                                        <span className='pi pi-search' style={{fontSize: 20, color: "#a3a3a3"}} ></span>
                                    </div>
                                    <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", width: "100%", flexGrow: 1, borderTopLeftRadius: 0, outline: "none", borderBottomLeftRadius: 0, height: "100%", padding: 5, border: "none", backgroundColor: " #fff" }} placeholder='Search a Member name or Account ID' />
                                    <button outlined={false} style={{ width: 50, height: 45, display: "flex", outline: "none", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#009688", border: "1px solid #009688", borderRadius: "0px 5px 5px 0px" }}>
                                        <i className='pi pi-align-justify' style={{color: "#fff"}}/>
                                    </button>
                                </div>
                            </div> */}
                        </div>

                        <div style={{ display: 'flex', flexDirection: "column", gap: 20, width: "100%", height: window.innerHeight - 100, padding: 5, marginBottom: 50 }}>

                            <div style={{ display: 'flex', flexDirection: "column", gap: 10 }}>
                                <div style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <span style={{ fontSize: 30, fontWeight: "bolder" }}>11:00 AM</span>
                                    </div>
                                    <div style={{ padding: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        {
                                            onoff.filter((i) => i.draw_time === '11:00 AM')?.length > 0 ?
                                                onoff.filter((i) => i.draw_time === '11:00 AM')[0]?.status === 'active' ?
                                                    <div onClick={() => onoffplayBtn('11:00 AM')} style={{ border: "1px solid #ddd", color: "#fff", fontWeight: "bolder", backgroundColor: "red", borderRadius: 5, padding: 5, width: 150, textAlign: "center" }}>Stop</div>
                                                    :
                                                    <div onClick={() => onoffplayBtn('11:00 AM')} style={{ border: "1px solid #ddd", color: "#fff", fontWeight: "bolder", backgroundColor: "green", borderRadius: 5, padding: 5, width: 150, textAlign: "center" }}>Start</div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <span style={{ fontSize: 30, fontWeight: "bolder" }}>03:00 PM</span>
                                    </div>
                                    <div style={{ padding: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        {
                                            onoff.filter((i) => i.draw_time === '03:00 PM')?.length > 0 ?
                                                onoff.filter((i) => i.draw_time === '03:00 PM')[0]?.status === 'active' ?
                                                    <div onClick={() => onoffplayBtn('03:00 PM')} style={{ border: "1px solid #ddd", color: "#fff", fontWeight: "bolder", backgroundColor: "red", borderRadius: 5, padding: 5, width: 150, textAlign: "center" }}>Stop</div>
                                                    :
                                                    <div onClick={() => onoffplayBtn('03:00 PM')} style={{ border: "1px solid #ddd", color: "#fff", fontWeight: "bolder", backgroundColor: "green", borderRadius: 5, padding: 5, width: 150, textAlign: "center" }}>Start</div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <span style={{ fontSize: 30, fontWeight: "bolder" }}>07:00 PM</span>
                                    </div>
                                    <div style={{ padding: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        {

                                            onoff.filter((i) => i.draw_time === '07:00 PM')?.length > 0 ?
                                                onoff.filter((i) => i.draw_time === '07:00 PM')[0]?.status === 'active' ?
                                                    <div onClick={() => onoffplayBtn('07:00 PM')} style={{ border: "1px solid #ddd", color: "#fff", fontWeight: "bolder", backgroundColor: "red", borderRadius: 5, padding: 5, width: 150, textAlign: "center" }}>Stop</div>
                                                    :
                                                    <div onClick={() => onoffplayBtn('07:00 PM')} style={{ border: "1px solid #ddd", color: "#fff", fontWeight: "bolder", backgroundColor: "green", borderRadius: 5, padding: 5, width: 150, textAlign: "center" }}>Start</div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default AdminManageLottery;
