import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";
import React from 'react';
// import Homepage from "./components/Homepage";
import Login from './sureball/Login'
import Dashboard from "./sureball/Dashboard";
import axios from "axios";
import ENV from "./screens/ENV";
import { useEffect, useMemo, useState } from "react";
import Patient from "./screens/Patient";
import Calendar from "./screens/Calendar";
import { AppProvider } from "./screens/context/AppContext";
import Reports from "./screens/Reports";
// import firebase from "./firebase";
import io from "socket.io-client";

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { format } from "date-fns";
import PosLogin from "./pos/Login";
import PosDashboard from "./pos/Dashboard";
import PosProduct from "./pos/Product";
import PosCashier from "./pos/Cashier";

import dir from 'C:\\Windows\\System32\\drivers\\etc\\react_register.txt';
import Lottery from "./sureball/Lottery";
import Wallet from "./sureball/Wallet";
import Register from "./sureball/Register";
import Account from "./sureball/Account";
import Administrator from "./sureball/Administrator";
import Member from "./sureball/Member";
import Commission from "./sureball/Commission";
import Transactions from "./sureball/Transactions";
import AdminMember from "./sureball/AdminMember";
import AdminBetPlay from "./sureball/AdminBetPlay";
// import RequestList from "./sureball/RequestList";
import Payout from "./sureball/Payout";
import AdminPayoutRequest from "./sureball/AdminPayoutRequest";
import Result from "./sureball/Result";
import Info from "./sureball/Info";
import CreditHistory from "./sureball/component/wallet/CreditHistory";
import AdminCreditCode from "./sureball/AdminCreditCode";
import AdminStatistics from "./sureball/AdminStatistics";
import AdminDepositeReport from "./sureball/AdminDepositeReport";
import AdminResult from "./sureball/AdminResult";
import AdminCoins from "./sureball/AdminCoins";
import AdminPayoutReport from "./sureball/AdminPayoutReport";
import Reward from "./sureball/Reward";
import AdminTickets from "./sureball/AdminTickets";
import AdminPayinRequest from "./sureball/AdminPayinRequest";
import AdminManageLottery from "./sureball/AdminManageLottery";

function App() {
    // localStorage.removeItem("computerKey")

    disableReactDevTools();
    // const socket = io.connect("http://192.168.1.11:3001");

    const position = sessionStorage.getItem('position');

    const [computerAddress, setComputerAddress] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [notification, setNotification] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [timeout, settimeout] = useState(0);
    const [newtransaction, setNewTransaction] = useState([])




    // const joinRoom = () => {
    //     socket.emit("join_room", '546546546')
    // }
    // useEffect(async()=>{     

    //     var formData = new FormData();
    //     formData.append("profile", 1);
    //     formData.append("user_id", sessionStorage.getItem('user_id'));

    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         // console.log(res.data)
    //         if(res.data[0]) {
    //             setUserInfo(res.data[0])
    //             setisLoading(false)
    //         } 
    //         // else {                
    //         //     setisLoading(false)
    //         //     sessionStorage.removeItem('user_id')
    //         //     sessionStorage.removeItem('username')
    //         // }
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // },[setUserInfo]);



    useEffect(() => {
        // socket.emit("clinic_data", {room: 123})
        // socket.on("receive_data", (data) => {
        //     if(data != null){                    
        //         console.log(data)
        //         setNewTransaction(data)
        //     }
        // })

        fetch(dir)
            .then(row => row.text())
            .then(text => {
                // setComputerAddress(text)
                if (text !== localStorage.getItem("computerKey")) {
                    // console.log('exit'+text)
                    localStorage.setItem("computerKey", text);
                    return;
                }
                // console.log('renew')
                localStorage.setItem("computerKey", text);
            });


    }, [dir])

    // console.log(localStorage.getItem("computerKey"))

    // if (localStorage.getItem("username") !== null) {
    //     return (
    //         <div style={{
    //             width:"100vw", 
    //             height:"100vh", 
    //             display:"flex", 
    //             flexDirection: "row", 
    //             justifyContent:"center", 
    //             alignItems:"center",
    //             fontSize: 60,
    //             fontWeight: 600,
    //             color: "rgb(255 124 124)",
    //             textShadow: "0 0 3px #000, 0 0 5px #000"
    //         }}>
    //             Device not allowed!
    //         </div>
    //     )
    // }
    // setTimeout(() => {
    //     return (
    //         <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>Loading...</div>
    //     )
    // }, 1000);
    // console.log(sessionStorage.getItem('username'))
    return (
        <AppProvider>
            <Router>
                <Switch>
                    <Route exact={true} path="/">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Dashboard userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/dashboard">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Dashboard userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/lottery">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Lottery userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/wallet">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Wallet userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/wallet/payout">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Payout userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/wallet/credithistory">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <CreditHistory userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/info">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Info userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/account">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Account userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/member">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Member userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/reward">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Reward userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/commission">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Commission userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/transactions">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Transactions userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/result">
                        {
                            sessionStorage.getItem('user_id') == null ? <Login /> : <Result userInfo={userInfo} />
                        }
                    </Route>
                    <Route exact={true} path="/register">
                        {
                            <Register />
                        }
                    </Route>
                    <Route exact={true} path="/administrator">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <Administrator />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/bet-player">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminBetPlay />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/bet-player/review/:draw_time">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminBetPlay />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/member">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminMember />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/result">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminResult />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/payin/request">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminPayinRequest />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/payout/request">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminPayoutRequest />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/credit/code">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminCreditCode />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/tickets">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminTickets />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/coins">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminCoins />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/statistics">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminStatistics />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/report/payout">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminPayoutReport />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/report/deposite">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminDepositeReport />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                    <Route exact={true} path="/administrator/manage/lottery">
                        {
                            sessionStorage.getItem('admin') === '1' ?
                                <AdminManageLottery />
                                :
                                <div style={{width: "100%", height: window.innerHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <span style={{fontSize: 70, fontWeight: "bolder", }}>404</span>
                                    <span style={{fontSize: 18, }}>Page not found</span>
                                    <span onClick={()=>window.location.assign('/')} style={{fontSize: 15, textDecoration: "underline", color: "orange", cursor: "pointer"}}>Back</span>
                                </div>
                        }
                    </Route>
                </Switch>
            </Router>
        </AppProvider>
    )
}

export default App;
