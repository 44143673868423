import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import MamberPanel from './component/member/MamberPanel';
import CreditCodeCard from './component/creditcode/CreditCodeCard';
import CreditAddBtn from './component/creditcode/CreditAddBtn';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const AdminDepositeReport = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [coins, setCoins] = useState([])
    const [creditlist, setCreditList] = useState([])
    const [search, setSearch] = useState('')
    const [statuscode, setStatusCode] = useState('open')
    const [wallet, setWallet] = useState({ "balance": "0.00" })
    const [user, setUser] = useState([])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("coins", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setCoins(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("creditlist", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setCreditList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setUser(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])


    useMemo(async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setWallet(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    const groupedData = coins?.filter((i)=>i)?.reduce((groups, item) => {
        const { datetime, amount } = item;
        // console.log(product_id)
        if (datetime == 0) {
            if (!groups[datetime]) {
                groups[datetime] = [];
            }
            groups[datetime].push(item);
        } else {
            if (!groups[datetime]) {
                groups[datetime] = [];
            }
            groups[datetime].push(item);
        }
        return groups;
    }, {});


    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header hidebalance={true} />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} titlestatus="Administrator" />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ backgroundImage: `url(${require('./includes/sball.png')})` }}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <div style={{ padding: 5, width: "100%" }}>
                            <span style={{ fontWeight: "bolder", fontSize: 20 }}>Deposite Report</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", gap: 5, width: "100%", padding: 5 }}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                <span>
                                    Total Deposit: <span style={{ fontWeight: "bolder" }}>
                                        {
                                            [...coins].filter((i) => {
                                                return i
                                            })?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                    </span>
                                </span>
                                {/* <span style={{textDecoration: "underline"}}>Statistics</span> */}
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                {
                                    Object.entries(groupedData).map(([datetime, items]) => {
                                        return (
                                            <div style={{ boxShadow: "0px 1px 2px grey", backgroundColor: "#dcdedc", borderRadius: 5 }}>
                                                <div style={{ width: "100%", padding: "0px 5px 0px 5px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    <span style={{ fontWeight: "bolder" }}>{datetime}</span>
                                                    <span style={{ fontWeight: "bolder" }}>Php {[...items]?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: "column", gap: 5, width: "100%", padding: 5 }}>
                                                    {
                                                        [...items].filter((i) => i).map((item, key) => {
                                                            return (
                                                                <div key={key} style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <span style={{ fontWeight: "normal", fontSize: 12 }}>{item.date_time}</span>
                                                                        <span style={{ fontWeight: "normal" }}></span>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <span style={{ fontWeight: "normal" }}><span style={{ fontWeight: "normal" }}>{[...user].filter((i) => i.user_id === item.user_id)[0]?.firstname}</span></span>
                                                                        <span style={{ fontWeight: "normal" }}>Php {Number(item.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>


                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default AdminDepositeReport;
