import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import { format } from 'date-fns';

import moment from 'moment';
import './member.css';
import axios from 'axios';
import ENV from '../../ENV';

const MamberPanel = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [wallet, setWallet] = useState({"balance": "0.00"})

    const LoadBalance = async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", props.item.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const openDialogBtn = () => {
        setOpenDialog(prev => ({ ...prev, "open": true }))
        LoadBalance()
    }

    // console.log(props.item.id, props.item.user_id)
    const updatePosition = async (e) => {        
        var formData = new FormData();
        formData.append("user_update_position", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("sponsor_id", props.item.sponsor_id);
        formData.append("account_type", props.item?.account_type);
        formData.append("position", e.target.value);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            props.setUser([...res.data])
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const DeleteAccountBtn = async () => {
        var formData = new FormData();
        formData.append("delete_user_account", 1);
        formData.append("user_id", props.item.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            props.setUser([...res.data])
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return (
        <>
        <Sidebar
            icons={
                <div className="widget-user-header bg-warning" style={{width: "100%", padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5}}>
                    <div className="widget-user-image">
                        <img className="img-circle elevation-2" style={{width: 80}} src="../dist/img/user7-128x128.jpg" alt="User Avatar" />
                    </div>
                    <div>
                        <h3 className="widget-user-username">{props.item.firstname}</h3>
                        <h5 className="widget-user-desc">
                            {
                                props.item?.account_type === 'general coordinator' ?
                                    <>General Coordinator</>
                                    :
                                    (
                                        props.item?.account_type === 'coordinator' ?
                                            <>Coordinator</>
                                            :
                                            (
                                                props.item?.account_type === 'agent' ?
                                                    <>Agent</>
                                                    :
                                                    <>Player</>
                                            )
                                    )
                            }
                        </h5>
                    </div>
                </div>
            }
            blockScroll={true}
            position='bottom'
            draggable={false}
            showCloseIcon={false}
            style={{ width: '100rem', height: 400, fontSize: 15, padding: 0 }} //, height: 500
            visible={openDialog.open}
            onHide={hideDialog}>
                
                <div className="card card-widget widget-user-2">
                    <div className="card-footer p-0">
                        <ul className="nav flex-column">
                            {
                                props.offoption === true ?
                                <li className="nav-item" style={{padding: 5}}>
                                    <select onChange={(e)=>updatePosition(e)} value={props.item?.account_type} style={{width: "100%", height: 35, borderRadius: 5, border: "none", outline: "none", paddingLeft: 10}}>
                                        <option value={"general coordinator"}>General Coordinator</option>
                                        <option value={"coordinator"}>Coordinator</option>
                                        <option value={"agent"}>Agent</option>
                                        <option value={"player"}>Player</option>
                                    </select>
                                </li>
                                :null
                            }
                            {
                                props.item?.account_type !== 'player' ?
                                    <li className="nav-item">
                                        <a className="nav-link" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <span>Account ID</span> <span className="">{props.item?.user_id}</span>
                                        </a>
                                    </li>
                                    : null
                            }
                            {
                                sessionStorage.getItem('admin') === String(1) ?
                                <li className="nav-item">
                                    <a className="nav-link" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                        <span>Balance</span> <span className="">{Number(wallet.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </a>
                                </li>
                                : null
                            }
                            <li className="nav-item">
                                <a className="nav-link" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <span>Phone</span> <span className="">{props.item?.contact_no}</span>
                                </a>
                            </li>
                            {
                                [...props?.user].filter((i)=> i?.user_id === props.item?.sponsor_id).length > 0 ?
                                    <li className="nav-item">
                                        <a className="nav-link" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <span>Sponsored By</span> <span className="">
                                                {
                                                    props.item?.sponsor_id === sessionStorage.getItem('user_id') ?
                                                        props.admin === 1 ?
                                                            [...props.user].filter((i) => i?.user_id === props.item?.sponsor_id)[0]?.firstname
                                                            :
                                                            "You"
                                                        :
                                                        [...props.user].filter((i) => i?.user_id === props.item?.sponsor_id)[0]?.firstname
                                                }
                                            </span>
                                        </a>
                                    </li>
                                :null
                            }
                            <li className="nav-item">
                                <a className="nav-link" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <span>Since Registered</span> <span className="">{moment(props.item?.date_time).format('MMMM DD, YYYY')}</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => DeleteAccountBtn()} className="nav-link" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <span style={{color: "red"}}>Delete Account</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Sidebar>
            {/* {JSON.stringify(props.item)} */}                                      
            <div onClick={() => openDialogBtn()} key={props.key} style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ fontWeight: "bolder" }}>{props.item?.firstname}</span>
                    <span>
                        {
                            props.item?.account_type === 'general coordinator' ?
                                <>General Coordinator</>
                                :
                                (
                                    props.item?.account_type === 'coordinator' ?
                                        <>Coordinator</>
                                        :
                                        (
                                            props.item?.account_type === 'agent' ?
                                                <>Agent</>
                                                :
                                                <>Player</>
                                        )
                                )
                        }
                    </span>
                </div>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                        {
                            props.item?.account_type !== 'player' ?
                                <span>ID: {props.item?.user_id}</span>
                                : null
                        }
                        <span>Phone: {props.item?.contact_no}</span>
                    </div>
                </div>
            </div>
            {/* <div onClick={() => openDialogBtn()} style={{ padding: 0, width: 40, textAlign: "center", borderRadius: 5, border: "1px solid #ddd" }}>
                <i className='pi pi-list' />
            </div> */}
        </>
    );
}

export default MamberPanel;
