// import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import swal from 'sweetalert';
import './wallet.css';

const CreditForm = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({ "user_id": sessionStorage.getItem('user_id'), "amount": 0, "method": "" })
    // console.log(inputvalue)
    const hideDialog = (event) => {
        // alert("")
        // console.log(event)
        setOpenDialog(prev => ({ ...prev, open: false }))
        setInputValue(prev => ({ ...prev, 'credit_image': "", "method": "" }))
    }

    const changeHandlerImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let files = event.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                setInputValue(prev => ({ ...prev, 'credit_image': e.target.result }))
            }
        }
    };

    const EnterCreditForm = async (event) => {
        event.preventDefault();
        if (inputvalue.amount>0 && inputvalue.credit_image!='') {
            var formData = new FormData();
            formData.append("buy_credit", 1); // enter_credit
            formData.append("inputvalue", JSON.stringify(inputvalue));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/process.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                if (res.data[0].status == 'success') {
                    swal({
                        text: "Successfully Sent Request",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        setOpenDialog(prev => ({ ...prev, open: false }))
                        setInputValue({ "user_id": sessionStorage.getItem('user_id'), "amount": 0, "method": "" })
                        props.reloadWallet()
                        props.reloadPayoutList()
                    });
                } else {
                    swal({
                        text: "Invalid",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    });
                }
            })
                .catch((err) => {
                    console.log(err);
                });
            // alert("success")
        } else {
            alert("invalid")
        }
    }

    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>Buy Credit</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: inputvalue.method !== '' ? 500 : "auto", fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5}}>
                    <button onClick={()=>setInputValue(prev => ({ ...prev, method: 'GCash' }))} className={inputvalue.method === 'GCash' ? 'btn btn-default btn-block' : 'btn btn-primary btn-block'} style={{margin: 0}}>GCash</button>
                    <button onClick={()=>setInputValue(prev => ({ ...prev, method: 'Maya' }))} className={inputvalue.method === 'Maya' ? 'btn btn-default btn-block' : 'btn btn-success btn-block'} style={{margin: 0}}>Maya</button>
                </div>
                {
                    inputvalue.method !== '' ?
                    <form onSubmit={EnterCreditForm} method="post">
                        <div>
                            <div>
                                <div style={{ fontWeight: "bolder", fontSize: 20 }}>{inputvalue.method} #: 09159832111</div>
                            </div>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <label>Request Amount</label>
                                    {/* <label onClick={()=>window.location.assign('/wallet/credithistory')} style={{textDecoration: "underline"}}>History</label> */}
                                </div>
                                <input className='form-control' name="amount" placeholder='0.00' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.account_name} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ padding: 5 }}>
                                    <span>Proof your {inputvalue.method} receipt</span>
                                </div>
    
                                <label>
                                    <div style={{ width: 200, minHeight: 50, border: "1px solid #ddd", borderRadius: 5 }}>
                                        <input type="file" placeholder="" style={{ display: "none" }} className="required" onChange={(e) => changeHandlerImage(e)} />
                                        <img src={inputvalue?.credit_image} style={{ width: "100%", height: "100%", border: "5px solid #fff", borderRadius: 5 }} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div style={{ padding: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <button type='submit' className='btn btn-sm btn-success'>Send Request</button>
                        </div>
                    </form>
                    : null
                }
            </Sidebar>
            <button onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{ width: 100 }} className='btn btn-warning btn-sm'>Buy Credit</button>
            {/* <button onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{ width: 100 }} className='btn btn-info btn-sm'>Payout</button> */}
        </>
    );
}

export default CreditForm;
