import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../ENV';

const GetBalance = (props) => {
    return (
        <>
            {Number(props?.wallet?.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {/* {JSON.stringify(props.wallet)} */}
        </>
    );
}

export default GetBalance;