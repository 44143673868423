import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import { Menu } from 'primereact/menu';

const TransferCoinsForm = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({"user_id": 0, "amount": 0})
    const [user, setUser] = useState([])
    // console.log(inputvalue)
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
        setInputValue({"user_id": 0, "amount": 0})
    }

    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setUser(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    const TransferCode = async () => {
        if (inputvalue.qty !== 0 && inputvalue.amount !== 0) {
            var formData = new FormData();
            formData.append("transfer_coins", 1);
            formData.append("inputvalue", JSON.stringify(inputvalue));
            await axios({
                method: "post",
                url: ENV.DOMAIN+'api/process.php',
                data: formData,
            }).then((res)=>{
                if (res.data.length>0) {
                    setOpenDialog(prev => ({ ...prev, open: false }))
                    props.setCoins([...res.data])
                    setInputValue({"user_id": 0, "amount": 0})
                    props.setStatusCode('open')
                }
            })
            .catch((err) => {
                console.log(err);
            });
        } else {

        }
    }

    // onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))}
    const menuRight = useRef(null);
    const newArray = [];

    const itemArray = () => {
        newArray.push({
            label: 'Transfer Coins',
            // icon: 'pi pi-refresh',
            command: () => {
                setOpenDialog(prev => ({ ...prev, open: true }))
            }
        })
        // if (props.statuscode === 'close') {
        //     newArray.push({
        //         label: 'Show Available',
        //         icon: 'pi pi-upload',
        //         command: ()=>{
        //             props.setStatusCode('open')
        //         }
        //     })
        // } else if (props.statuscode === 'open') {
        //     newArray.push({
        //         label: 'Show Used Code',
        //         icon: 'pi pi-upload',
        //         command: ()=>{
        //             props.setStatusCode('close')
        //         }
        //     })
        // }
        return newArray;
    }



    const items = [
        {
            label: 'Option',
            items: itemArray()
        }
    ];

    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>Transfer Coins</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 250, fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>
                    <div style={{textAlign: "center", fontWeight: "bolder"}}>
                        {
                            [...user].filter((i)=>{
                                if (inputvalue.user_id.length >= 3) {
                                    return i.user_id.includes(inputvalue.user_id);
                                }
                            })[0]?.firstname
                        }                        
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10}}>
                            <input name='user_id' onChange={(e) => setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} style={{ width: "50%", height: 45, flexGrow: 1, outline: "none", padding: 5, backgroundColor: "#fff", border: "1px solid #ddd", borderRadius: 5 }} placeholder='Enter ID' />
                            <input name='amount' onChange={(e) => setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} style={{ width: "50%", height: 45, flexGrow: 1, outline: "none", padding: 5, backgroundColor: "#fff", border: "1px solid #ddd", borderRadius: 5 }} placeholder='Amount' />
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <button onClick={()=>TransferCode()} style={{ width: "100%", height: 45, display: "flex", outline: "none", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#009688", border: "1px solid #009688", borderRadius: 5, color: "#fff" }}>Save</button>
                        </div>
                    </div>
            </Sidebar>
            <button onClick={(event) => menuRight.current.toggle(event)} outlined={false} style={{ width: 50, height: 45, display: "flex", outline: "none", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#009688", border: "1px solid #009688", borderRadius: "5px 5px 5px 5px" }}>
                <i className='pi pi-list' style={{ color: "#fff" }} />
            </button>  
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />            
        </>
    );
}

export default TransferCoinsForm;
