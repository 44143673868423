import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import './wallet.css';
import PayoutHistoryPanel from './PayoutHistoryPanel';

const WalletHistory = (props) => {
    return (
        <div className="row">
            <div className='hidescroll' style={{display: 'flex', flexDirection: "column", gap: 5, width: "100%", height: window.innerWidth === 320 ? 260 : null, overflowX: "auto"}}>
                {
                    [...props.payoutlist].filter((i)=>i.user_id === sessionStorage.getItem('user_id')).map((item)=>{
                        return (
                            <PayoutHistoryPanel item={item} />
                        )
                    })
                }
            </div>
        </div>
    );
}

export default WalletHistory;
