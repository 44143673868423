import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useRef, useState } from 'react';
import ENV from '../../ENV';
import { Menu } from 'primereact/menu';

const CreditAddBtn = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({"qty": 0, "amount": 0})
    // console.log(inputvalue)
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const AddCredit = async () => {
        if (inputvalue.qty !== 0 && inputvalue.amount !== 0) {
            var formData = new FormData();
            formData.append("addcredit", 1);
            formData.append("inputvalue", JSON.stringify(inputvalue));
            await axios({
                method: "post",
                url: ENV.DOMAIN+'api/process.php',
                data: formData,
            }).then((res)=>{
                if (res.data.length>0) {
                    setOpenDialog(prev => ({ ...prev, open: false }))
                    props.setCreditList([...res.data])
                    setInputValue({"qty": 0, "amount": 0})
                    props.setStatusCode('open')
                }
            })
            .catch((err) => {
                console.log(err);
            });
        } else {

        }
    }

    // onClick={()=>setOpenDialog(prev => ({ ...prev, open: true }))}
    const menuRight = useRef(null);
    const newArray = [];

    const itemArray = () => {
        newArray.push({
            label: 'Add Credit',
            // icon: 'pi pi-refresh',
            command: () => {
                setOpenDialog(prev => ({ ...prev, open: true }))
            }
        })
        if (props.statuscode === 'close') {
            newArray.push({
                label: 'Show Available',
                // icon: 'pi pi-upload',
                command: ()=>{
                    props.setStatusCode('open')
                }
            })
        } else if (props.statuscode === 'open') {
            newArray.push({
                label: 'Show Used Code',
                // icon: 'pi pi-upload',
                command: ()=>{
                    props.setStatusCode('close')
                }
            })
        }
        return newArray;
    }



    const items = [
        {
            label: 'Option',
            items: itemArray()
        }
    ];

    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>Generate New Code</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 200, fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>
                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10}}>
                            <input name='qty' onChange={(e) => setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} style={{ width: "50%", height: 45, flexGrow: 1, outline: "none", padding: 5, backgroundColor: "#fff", border: "1px solid #ddd", borderRadius: 5 }} placeholder='Enter Qty' />
                            <input name='amount' onChange={(e) => setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} style={{ width: "100%", height: 45, flexGrow: 1, outline: "none", padding: 5, backgroundColor: "#fff", border: "1px solid #ddd", borderRadius: 5 }} placeholder='Amount' />
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <button onClick={()=>AddCredit()} style={{ width: "100%", height: 45, display: "flex", outline: "none", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#009688", border: "1px solid #009688", borderRadius: 5, color: "#fff" }}>Save</button>
                        </div>
                    </div>
            </Sidebar>
            <button onClick={(event) => menuRight.current.toggle(event)} outlined={false} style={{ width: 50, height: 45, display: "flex", outline: "none", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#009688", border: "1px solid #009688", borderRadius: "0px 5px 5px 0px" }}>
                <i className='pi pi-list' style={{ color: "#fff" }} />
            </button>  
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />            
        </>
    );
}

export default CreditAddBtn;
