import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import EnterResult from './component/admin/EnterResult';
import AdminHeader from './component/AdminHeader';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Administrator = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [user, setUser] = useState([])

    const [wallet, setWallet] = useState({ "balance": "0.00" })
    const [result, setResult] = useState([])
    const [payoutlist, setPayoutList] = useState([])
    const [ticketcount, setTicketCount] = useState([])
    const [fund, setFund] = useState(0)
    const [coins, setCoins] = useState([])
    const [onoff, setOnOff] = useState([])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("onoffplayfetch", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setOnOff(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("admin_fund", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setFund(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("coins", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setCoins(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setUser(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setWallet(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("result", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data[0])
            setResult(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("ticket_count", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setTicketCount(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])


    useMemo(async () => {
        var formData = new FormData();
        formData.append("payoutlist", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV])

    const onoffplayBtn = async (draw_time) => {
        console.log(draw_time)
        var formData = new FormData();
        formData.append("onoffplayupdate", 1);
        formData.append("draw_time", draw_time);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            setOnOff([...res.data])
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const transferred = [...coins].filter((i) => {
        return i;
    })?.reduce((amount, item) => amount = Number(item.amount) + amount, 0);
    // console.log(result?.result_today, result.result_today?.filter((i) => i.drawtime === '07:00 PM').map((i) => i).length)






    
    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <AdminHeader hidebalance={true} />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} titlestatus="Administrator" />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ backgroundImage: `url(https://surewin88.net/img/sball.png)` }}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content" style={{ marginBottom: 20 }}>
                    <div className="container-fluid">
                        {/* <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            <div style={{ display: 'flex', flexDirection: "column", gap: 10, width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 5, backgroundColor: "#fff", justifyContent: "space-between", alignItems: "flex-start", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                    <span style={{ fontSize: 20 }}>Fund</span>
                                    <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>
                                        <span style={{ fontWeight: "bolder", fontSize: 25 }}>{Number(fund.amount - transferred)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        <span style={{ fontWeight: "bolder", fontSize: 15 }}>Transferred: {Number(transferred)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                </div>
                                {
                                    [...payoutlist].filter((i) => {
                                        return i.status === 'topup'
                                    }).map((item, key) => item).length > 0 ?
                                        <div style={{ display: "flex", flexDirection: "row", gap: 5, backgroundColor: "#fff", justifyContent: "space-between", alignItems: "center", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                            <div>
                                                <span>Cashin Request</span>
                                            </div>
                                            <div onClick={() => window.location.assign('/administrator/payin/request')} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center" }}>
                                                <span style={{ paddingLeft: 5, paddingRight: 5, textAlign: "center", backgroundColor: "red", color: "#fff", borderRadius: 100, minWidth: 10 }}>
                                                    {
                                                        [...payoutlist].filter((i) => {
                                                            return i.status === 'topup'
                                                        }).map((item, key) => item).length
                                                    }
                                                </span>
                                                <span className='pi pi-chevron-right' />
                                            </div>
                                        </div>
                                        : null
                                }
                                <div style={{ boxShadow: "-1px 2px 5px grey", backgroundColor: "#fff", borderRadius: 5, padding: 5 }}>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                        <span>Total Registered</span>
                                        <span style={{ fontWeight: "bolder", fontSize: 18 }}>{[...user].length}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                        <span>General Coordinator</span>
                                        <span style={{ fontWeight: "bolder", fontSize: 18 }}>{[...user].filter((i) => i.account_type === 'general coordinator').length}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                        <span>Coordinator</span>
                                        <span style={{ fontWeight: "bolder", fontSize: 18 }}>{[...user].filter((i) => i.account_type === 'coordinator').length}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                        <span>Agent</span>
                                        <span style={{ fontWeight: "bolder", fontSize: 18 }}>{[...user].filter((i) => i.account_type === 'agent').length}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                        <span>Player</span>
                                        <span style={{ fontWeight: "bolder", fontSize: 18 }}>{[...user].filter((i) => i.account_type === 'player').length}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div style={{ width: "100%", padding: 5 }}>
                            <div style={{ padding: 5, backgroundColor: "#9565c7", display: "flex", flexDirection: "column", width: "100%", boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                <div onClick={()=>window.location.assign('/administrator/member')} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{color: "#fff"}}>Member List</span>
                                    <span className='pi pi-chevron-right' />
                                </div>
                            </div>
                        </div> */}
                        <div style={{ width: "100%", padding: 5 }}>
                            <div style={{ padding: 5, backgroundColor: "#d3b9ed", display: "flex", flexDirection: "column", width: "100%", boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                <div style={{ borderBottom: "1px solid #ddd" }}>
                                    <div onClick={() => window.location.assign('/administrator/payout/request')} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <span>Payout Request</span>
                                        <span className='pi pi-chevron-right' />
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
                                    <span>Total Request: {[...payoutlist].filter((i) => i.status === 'pending').length}</span>
                                    <span>Total Payout: {[...payoutlist].filter((i) => i.status === 'pending')?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* Left col */}
                        {/* /.Left col */}
                        {/* right col (We are only adding the ID to make the widgets sortable)*/}
                        <div style={{ display: 'flex', flexDirection: "column", gap: 5, padding: 5, width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                <span>Draw Time: 11:00 AM</span>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* <span>Total Bet: 0</span> */}
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                                        <EnterResult drawtime="11:00" meridiem="AM" ticketcount={ticketcount} />
                                        {
                                            [...onoff].filter((i) => i.draw_time === '11:00 AM')?.length > 0 ?
                                                [...onoff].filter((i) => i.draw_time === '11:00 AM')[0]?.status === 'active' ?
                                                    <span onClick={() => onoffplayBtn('11:00 AM')} style={{cursor: "pointer", textDecoration: "underline", color: "red"}}>Stop</span>
                                                    :
                                                    <span onClick={() => onoffplayBtn('11:00 AM')} style={{cursor: "pointer", textDecoration: "underline", color: "green"}}>Start</span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                <span>Draw Time: 03:00 PM</span>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* <span>Total Bet: 0</span> */}
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                                        <EnterResult drawtime="03:00" meridiem="PM" ticketcount={ticketcount} />
                                        {
                                            [...onoff].filter((i) => i.draw_time === '03:00 PM')?.length > 0 ?
                                                [...onoff].filter((i) => i.draw_time === '03:00 PM')[0]?.status === 'active' ?
                                                    <span onClick={() => onoffplayBtn('03:00 PM')} style={{cursor: "pointer", textDecoration: "underline", color: "red"}}>Stop</span>
                                                    :
                                                    <span onClick={() => onoffplayBtn('03:00 PM')} style={{cursor: "pointer", textDecoration: "underline", color: "green"}}>Start</span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                <span>Draw Time: 07:00 PM</span>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* <span>Total Bet: 0</span> */}
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                                        <EnterResult drawtime="07:00" meridiem="PM" ticketcount={ticketcount} />
                                        {
                                            [...onoff].filter((i) => i.draw_time === '07:00 PM')?.length > 0 ?
                                                [...onoff].filter((i) => i.draw_time === '07:00 PM')[0]?.status === 'active' ?
                                                    <span onClick={() => onoffplayBtn('07:00 PM')} style={{cursor: "pointer", textDecoration: "underline", color: "red"}}>Stop</span>
                                                    :
                                                    <span onClick={() => onoffplayBtn('07:00 PM')} style={{cursor: "pointer", textDecoration: "underline", color: "green"}}>Start</span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div style={{ padding: 5 }}>
                            <span style={{ fontWeight: "bolder" }}>Result</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", gap: 5, padding: 5, width: "100%" }}>
                            {

                                result?.result_today?.length > 0 ?
                                    result?.result_today.map((item, key) => {
                                        return (
                                            <div style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                                <span>Draw Time: {item.drawtime}</span>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    <span style={{ fontSize: 15, fontWeight: "bolder" }}>Result: {item?.number?.split('-')[0]}-{item?.number?.split('-')[1]}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null
                            }
                        </div>
                    </div>

                    {/* <div className="row">
                        <div style={{ padding: 5 }}>
                            <span style={{ fontWeight: "bolder" }}>Winner for Today</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", gap: 5, padding: 5, width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                <span>Draw Time: 11:00 AM</span>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>Total Bet: 0</span>
                                    <span className='btn btn-warning btn-xs'>View</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                <span>Draw Time: 03:00 PM</span>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>Total Bet: 0</span>
                                    <span className='btn btn-warning btn-xs'>View</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                                <span>Draw Time: 07:00 PM</span>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>Total Bet: 0</span>
                                    <span className='btn btn-warning btn-xs'>View</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Administrator;
