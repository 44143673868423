import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';

const DialogPanel = (props) => {
    const { item, user, loadpayoutlist } = props;
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({...item, "amount": 0})
    // console.log(inputvalue)
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
        setInputValue(prev => ({...prev, "amount": 0}))
        loadpayoutlist()
    }
    
    const ConfirmBtn = async (status) => {
        if (status === 'confirm') {
            if (Number(inputvalue.amount)>0) {
                if (Number(item.amount) === Number(inputvalue.amount)) {
                    var formData = new FormData();
                    formData.append("confirm_buycredit", 1);
                    formData.append("inputvalue", JSON.stringify(inputvalue));
                    await axios({
                        method: "post",
                        url: ENV.DOMAIN + 'api/process.php',
                        data: formData,
                    }).then((res) => {
                        swal({
                            text: "Confirmed",
                            icon: "success",
                            timer: 1000,
                            button: false,
                        }).then(() => {
                            hideDialog()
                        });
                    })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    swal({
                        text: "Invalid",
                        icon: "error",
                        timer: 1000,
                        button: false,
                    });
                }
            } else {
                swal({
                    text: "Invalid",
                    icon: "error",
                    timer: 1000,
                    button: false,
                });
            }
        } else if (status === 'decline') {
            var formData = new FormData();
            formData.append("decline_buycredit", 1);
            formData.append("inputvalue", JSON.stringify(inputvalue));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/process.php',
                data: formData,
            }).then((res) => {
                swal({
                    text: "Successfully Declined",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    hideDialog()
                });
            })
                .catch((err) => {
                    console.log(err);
                });
        }
    }


    return (
        <div>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>{[...user].filter((i) => i.user_id === inputvalue.user_id)[0]?.firstname}</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 600, fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>
                    <div>
                        <div>
                            <span>Enter to Confirm Amount {`"${Number(item.amount)}"`}</span>
                        </div>
                        <div>
                            <input name='amount' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} className='form-control' placeholder={Number(item.amount)} />
                        </div>
                        <div style={{padding: 5, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10}}>
                            <button onClick={()=>ConfirmBtn('confirm')} className='btn btn-info btn-sm'>Confirm</button>
                            <button onClick={()=>ConfirmBtn('decline')} className='btn btn-danger btn-sm'>Decline</button>
                        </div>
                        <div style={{padding: 10}}>
                            <div style={{padding: 5}}>
                                <span>Proof of Payment</span>
                            </div>
                            <div style={{padding: 5}}>
                                <img src={ENV.IMAGEDIR+item.credit_image} style={{width: "100%"}} />
                            </div>
                        </div>
                    </div>
            </Sidebar>
            <div onClick={()=>setOpenDialog(prev=>({...prev, open: true }))} style={{ width: "100%", border: "1px solid #ddd", backgroundColor: "#fff", padding: 5, borderRadius: 5, boxShadow: "-1px 2px 5px grey" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ fontSize: 12 }}>User ID: <span style={{ fontWeight: "bolder" }}>{inputvalue.user_id}</span></span>
                    <span style={{ fontSize: 12 }}>{item.date_time}</span>
                </div>
                <div style={{ width: "100%", border: "1px solid #ddd" }}></div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ fontSize: 12 }}>Name: <span style={{ fontWeight: "bolder" }}>{[...user].filter((i) => i.user_id === inputvalue.user_id)[0]?.firstname}</span></span>
                    <span style={{ fontSize: 12 }}>
                        <span style={{ fontWeight: "bolder" }}>
                            {
                                [...user].filter((i) => i.user_id === inputvalue.user_id)[0]?.account_type === 'general coordinator' ?
                                    "General Coordinatot"
                                    :
                                    [...user].filter((i) => i.user_id === inputvalue.user_id)[0]?.account_type === 'coordinator' ?
                                        "Coordinatot"
                                        :
                                        [...user].filter((i) => i.user_id === inputvalue.user_id)[0]?.account_type === 'agent' ?
                                            "Agent"
                                            :
                                            "Player"
                            }
                        </span>
                    </span>
                </div>
                <div>
                    <span style={{ fontSize: 12 }}>Amount: <span style={{ fontWeight: "bolder" }}>{Number(item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
                </div>
            </div>
        </div>
    )
}

export default DialogPanel;
