import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';

const TicketOrder = (props) => {
    const [visible, setVisible] = useState(false);
    const [wallet, setWallet] = useState({"balance": "0.00"})

    const handleClick = () => {
        setVisible(false)
    }

    

    // useMemo(async()=> {
    //     var formData = new FormData();
    //     formData.append("pick_time", 1);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'api/process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         setPickTime(res.data)
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // }, [ENV])

    const data = props.item.list.filter((i)=>i.order_id === props.item.order_id)
    
    const Total = data?.filter((i)=>i.status === 'WAITING' || i.status === 'WIN' || i.status === 'LOSE')?.reduce((amount, item) => amount = Number(item.amount) + amount, 0);

    return (
        <>
            <Sidebar 
            style={{backgroundImage: `url(${require('../includes/sball.png')})`}}
            visible={visible}
            onHide={handleClick}
            showCloseIcon={false}
            >
                <div>

                    <div onClick={()=>setVisible(true)}>
                        <div style={{fontWeight: "bolder"}}>Game: STL-SILAY</div>
                        <div style={{fontWeight: "bolder"}}>Trans ID: {props.item.order_id}</div>
                        <div style={{fontWeight: "bolder"}}>Trans Date: {props.item.date_time}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: 2}}>
                        {
                            props.item.list.map((item)=>{
                                // const number = item.number.split('-')
                                return (
                                    <div style={{fontSize: 12, borderRadius: 5, border: "1px solid #ddd", display: "flex", flexDirection: "column"}}>
                                        <div style={{fontSize: 12, padding: 5, display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems: "center"}}>
                                            <div>{item.number}</div>
                                            <div>STD</div>
                                            <div>{item.time_draw === '10:45 AM' ? '11:00 AM' : item.time_draw === '02:45 PM' ? '03:00 PM' : '07:00 PM' }</div>
                                            <div>{Number(item.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            <div>
                                                {
                                                    item.status === 'DECLINED' ? (
                                                        <div style={{padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "red", color: "#fff"}}>Declined</div>
                                                    ) : (
                                                        <div style={{padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "rgb(104 190 159)", color: "#fff"}}>Sent</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {
                                            item.status === 'WIN' ?
                                            <div style={{fontSize: 12, paddingLeft: 5, color: "rgb(104 190 159)"}}>REWARD: <span>{Number(item.reward).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                            : null
                                        }
                                    </div>
                                )
                            })
                        }
                        {/* <div style={{fontSize: 12, borderRadius: 5, border: "1px solid #ddd", padding: 5, display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems: "center"}}>
                            <div>10-38</div>
                            <div>STD</div>
                            <div>00:00 AM</div>
                            <div>100.00</div>
                            <div>
                                <div style={{padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "rgb(104 190 159)", color: "#fff"}}>Sent</div>
                            </div>
                        </div> */}
                    </div>
                    <div>
                        <div style={{fontSize: 12, fontWeight: "bolder"}}>Total: {Total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    </div>
                </div>
                <div style={{position: "absolute", bottom: 0, right: 0,padding: 10}}>
                    <button className='btn btn-danger btn-sm' onClick={()=>handleClick()}>Close</button>
                </div>
            </Sidebar>
            <div onClick={()=>setVisible(true)} style={{padding: 10, border:"1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", boxShadow: "0px 1px 2px grey"}}>
                <div>Game: STL-SILAY</div>
                <div>Trans ID: {props.item.order_id}</div>
                <div>Trans Date: {props.item.date_time}</div>
                <div style={{fontWeight: "bolder"}}>Ticket: {props.item.number}</div>
                <div style={{fontWeight: "bolder"}}>Draw: {props.item.time_draw === '10:45 AM' ? '11:00 AM' : props.item.time_draw === '02:45 PM' ? '03:00 PM' : '07:00 PM' }</div>
                <div style={{fontWeight: "bolder"}}>Amount: {Number(Total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </div>
        </>
    );
}

export default TicketOrder;
