// import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import swal from 'sweetalert';
import './wallet.css';

const PayoutHistoryPanel = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({"user_id": sessionStorage.getItem('user_id'), "credit_code": ""})
    // console.log(inputvalue)
    const hideDialog = (event) => {
        // alert("")
        // console.log(event)
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const EnterCreditForm = async (event) => {
        event.preventDefault();

        var formData = new FormData();
        formData.append("enter_credit", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));

        if (inputvalue.credit_code === '') {
            swal({
                title: "Invalid!",
                text: "Please complete the form",
                icon: "error",
                timer: 1000,
                button: false,
            });
            return;
        }

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'api/process.php',
            data: formData,
        }).then((res) => {
            if (res.data[0].status == 'success') {
                swal({
                    // title: "",
                    text: "Successfully Topup",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    setOpenDialog(prev => ({ ...prev, open: false }))
                    setInputValue({"user_id": sessionStorage.getItem('user_id'), "credit_code": ""})
                    props.reloadWallet()
                    props.reloadPayoutList()
                });
            } else {
                swal({
                    // title: "Invalid!",
                    text: "Invalid",
                    icon: "error",
                    timer: 2000,
                    button: false,
                });
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>Details</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 300, fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>
                    <div style={{wordWrap: "break-word"}}>

                        {/* {JSON.stringify(props.item)} */}
                        <div>
                            <span>
                            {
                                props.item.status === 'pending' ?
                                    <span style={{ fontSize: 12, color: "red" }}>Pending</span>
                                    :
                                    (
                                        props.item.status === 'approved' ?
                                            <span style={{ fontSize: 12, color: "green" }}>Approved</span>
                                            :
                                            (
                                                props.item.status === 'cancelled' ?
                                                    <span style={{ fontSize: 12, color: "red" }}>Cancelled</span>
                                                    :
                                                    (
                                                        props.item.status === 'declined' ?
                                                            <span style={{ fontSize: 12, color: "red" }}>Declined</span>
                                                            :
                                                            (
                                                                props.item.status === 'converted' ?
                                                                    <span style={{ fontSize: 12, color: "red" }}>Converted</span>
                                                                    :
                                                                    <span style={{ fontSize: 12, color: "red" }}>Payout</span>
                                                            )
                                                    )
                                            )
                                    )
                            }
                            </span>
                        </div>
                        <div>
                            <span>Amount: {Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </div>
                        <div>
                            <span>Method: {props.item.method}</span>
                        </div>
                        <div>
                            <span>Date Request: {props.item.date_time}</span>
                        </div>
                        {
                            props.item.status === 'approved' ? 
                            <>
                                <hr />
                                <div>
                                    <span>Date Approved: {props.item.date_approve}</span>
                                </div>
                                <div>
                                    <span>Ref No.: {props.item.reference_no}</span>
                                </div>
                            </>
                            : null
                        }

                    </div>
            </Sidebar>
            
            {/* onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} */}
            <div className="info" style={{ display: "flex", flexDirection: "column", gap: 0, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    {
                        props.item.status === 'topup' ?
                            <span style={{ color: "orange", fontWeight: "bolder" }}>Buy Credit</span>
                            :
                            props.item.status === 'topup_approved' ?
                                <span style={{ color: "green", fontWeight: "bolder" }}>Buy Credit</span>
                                :
                                props.item.status === 'topup_declined' ?
                                    <span style={{ color: "red", fontWeight: "bolder" }}>Buy Credit</span>
                                    :
                                    props.item.status === 'converted' ?
                                        <span style={{ fontWeight: "bolder" }}>Converted from Commission</span>
                                        :
                                        props.item.status === 'buy_ticket' ?
                                            <span style={{ fontWeight: "bolder" }}>Buy Ticket</span>
                                            :
                                            props.item.status === 'pending' ?
                                                <span style={{ fontWeight: "bolder" }}>Payout</span>
                                                :
                                                props.item.status === 'declined' ?
                                                    <span style={{ fontWeight: "bolder" }}>Payout</span>
                                                    :
                                                    null
                    }
                    {
                        props.item.status === 'pending' ?
                            <span style={{ fontSize: 12, color: "red" }}>Pending Payout</span>
                            :
                            (
                                props.item.status === 'approved' ?
                                    <span style={{ fontSize: 12, color: "green" }}>Approved</span>
                                    :
                                    (
                                        props.item.status === 'cancelled' ?
                                            <span style={{ fontSize: 12, color: "red" }}>Cancelled</span>
                                            :
                                            (
                                                props.item.status === 'declined' ?
                                                    <span style={{ fontSize: 12, color: "red" }}>Declined</span>
                                                    :
                                                    (
                                                        props.item.status === 'topup' ?
                                                            <span style={{ fontSize: 12, color: "orange" }}>Pending</span>
                                                            :
                                                            (
                                                                props.item.status === 'topup_approved' ?
                                                                    <span style={{ fontSize: 12, color: "green" }}>Completed</span>
                                                                    :
                                                                    (
                                                                        props.item.status === 'topup_declined' ?
                                                                            <span style={{ fontSize: 12, color: "red" }}>Declined</span>
                                                                            :
                                                                            (
                                                                                props.item.status === 'converted' ?
                                                                                    <span style={{ fontSize: 12, color: "green" }}>Converted</span>
                                                                                    :
                                                                                    (
                                                                                        props.item.status === 'buy_ticket' ?
                                                                                            <span style={{ fontSize: 12, color: "green" }}></span>
                                                                                            :
                                                                                            <span style={{ fontSize: 12, color: "red" }}>Payout</span>
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            )
                                    )
                            )
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    {
                        props.item.status === 'topup' ?
                            <span style={{ color: "orange" }}>Amount: {Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            : props.item.status === 'topup_approved' ?
                                <span style={{ color: "green" }}>Amount: +{Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                : props.item.status === 'topup_declined' ?
                                    <span style={{ color: "red" }}>Amount: {Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    :
                                    props.item.status === 'pending' ?
                                        <span style={{ color: "red" }}>Amount: -{Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        :
                                        props.item.status === 'approved' ?
                                            <span style={{ color: "red" }}>Amount: -{Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                            :
                                            props.item.status === 'converted' ?
                                                <span style={{ color: "green" }}>Amount: +{Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                :
                                                props.item.status === 'cancelled' ?
                                                    <span>Amount: {Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                    :
                                                    props.item.status === 'declined' ?
                                                        <span>Amount: {Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                        :
                                                        props.item.status === 'buy_ticket' ?
                                                            <span style={{ color: "red" }}>Amount: -{Number(props.item.amount)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                            :
                                                            null
                    }
                </div>
                {
                    props.item.status === 'pending' || props.item.status === 'approved' ?
                        <>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <span>Method: <span style={{fontWeight: "bolder"}}>{props.item.method}</span></span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <span>Fee: {Number(props.item.fee)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <span>Recieve: {Number(props.item.amount-props.item.fee)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </div>
                        </>
                        : null
                }
                {/* {
                    props.item.status === 'topup_approved' ?
                    <span style={{ fontSize: 12 }}>{props.item?.date_approve}</span>
                    :
                } */}
                <span style={{ fontSize: 12 }}>{props.item?.date_time}</span>
            </div>
            {/* <button onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{width: 100}} className='btn btn-warning btn-sm'>Credits</button> */}
            {/* <button onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{ width: 100 }} className='btn btn-info btn-sm'>Payout</button> */}
        </>
    );
}

export default PayoutHistoryPanel;
