import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import MamberPanel from './component/member/MamberPanel';
import AdminResultList from './component/result/AdminResult/AdminResultList';
import TicketPanel from './component/ticket/TicketPanel';
import { format } from 'date-fns';
import './extra/css/AdminTickets.css'


// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const AdminTickets = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [tickets, setTickets] = useState([])
    const [resultwinner, setResultWinner] = useState([])
    const [search, setSearch] = useState('')
    const [wallet, setWallet] = useState({"balance": "0.00"})
    const [user, setUser] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("alltickets", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setTickets(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("result_winner", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setResultWinner(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])


    useMemo(async()=> {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    const groupedData = tickets?.reduce((groups, item) => {
        const { datetime, number } = item;
        // console.log(product_id)
        if (datetime == 0) {
            if (!groups[datetime]) {
                groups[datetime] = [];
            }
            groups[datetime].push(item);
        } else {
            if (!groups[datetime]) {
                groups[datetime] = [];
            }
            groups[datetime].push(item);
        }
        return groups;
    }, {});

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                <Header wallet={wallet} titlepage="Tickets" hidebalance={true} />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} titlestatus="Administrator" />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{backgroundImage: `url(${require('./includes/sball.png')})`}}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* <section className="content-header"> */}
                            {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div> */}
                        {/* </section> */}

                        
                        
                        
                        {/* /.row (main row) */}
                    </div>

                    <div className="row">
                        <div style={{padding: 5, width: "100%"}}>
                            {/* <span style={{fontWeight: "bolder", fontSize: 20}}>Members</span> */}
                            
                            {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, width: "100%" }}>
                                <div style={{ border: "1px solid #009688", borderRadius: 6, display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ width: 40, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: "5px 0px 0px 5px", backgroundColor: " #fff" }}>
                                        <span className='pi pi-search' style={{fontSize: 20, color: "#a3a3a3"}} ></span>
                                    </div>
                                    <input onChange={(e) => setSearch(e.target.value)} style={{ outline: "none", width: "100%", flexGrow: 1, borderTopLeftRadius: 0, outline: "none", borderBottomLeftRadius: 0, height: "100%", padding: 5, border: "none", backgroundColor: " #fff" }} placeholder='Search a Member name or Account ID' />
                                    <button outlined={false} style={{ width: 50, height: 45, display: "flex", outline: "none", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#009688", border: "1px solid #009688", borderRadius: "0px 5px 5px 0px" }}>
                                        <i className='pi pi-align-justify' style={{color: "#fff"}}/>
                                    </button>
                                </div>
                            </div> */}
                        </div>
                        
                        <div style={{display: 'flex', flexDirection: "column", gap: 20, width: "100%", height: window.innerHeight-100, padding: 5, marginBottom: 50, overflowY: "scroll"}} className='AdminTicketHideScroll'>
                            {
                                    Object.entries(groupedData).map(([datetime, items]) => {
                                        return (
                                            <div style={{border: "1px solid #ddd", borderRadius: 5, backgroundColor: "rgb(148 255 234)"}}>
                                                <div style={{padding: "0px 0px 0px 5px", textAlign: "center", fontWeight: "bolder", fontSize: 20}}>
                                                    {
                                                        format(new Date(datetime), 'MMMM dd, yyyy')                                                        
                                                    }
                                                </div>
                                                <div style={{display: 'flex', flexDirection: "column", gap: 10, width: "100%", padding: 2}}>
                                                    {
                                                        Object.entries([...items[0].list]?.reduce((groups, item) => {
                                                            const { datetime, time_draw } = item;                                                            
                                                            if (!groups[time_draw]) {
                                                                groups[time_draw] = [];
                                                            }
                                                            groups[time_draw].push(item);
                                                            return groups;
                                                        }, {})).map((item, key) => {
                                                            // console.log(datetime, item[0], item[1])
                                                            return (
                                                                <div style={{border: "1px solid #fff", borderRadius: 5, boxShadow: "-1px 2px 5px grey", backgroundColor: "#fff"}}>
                                                                    <div style={{padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#ddd", color: "#000"}}>
                                                                        <span style={{fontWeight: "bolder"}}>                                                                            
                                                                            {
                                                                                item[0] === '10:45 AM' ?
                                                                                    "11:00 AM"
                                                                                    :
                                                                                    item[0] === '02:45 PM' ?
                                                                                        "3:00 PM"
                                                                                        :
                                                                                        item[0] === '06:45 PM' ?
                                                                                            "7:00 PM"
                                                                                            : null
                                                                            }
                                                                        </span>
                                                                        <span>Total Tickets: <span style={{fontWeight: "bolder"}}>{item[1].length}</span></span>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            // [...item[1]]
                                                                            Object.entries([...item[1]]?.reduce((groups, item) => {
                                                                                const { datetime, number } = item;                                                            
                                                                                if (!groups[number]) {
                                                                                    groups[number] = [];
                                                                                }
                                                                                groups[number].push(item);
                                                                                return groups;
                                                                            }, {})).map((tickets)=>{
                                                                                console.log(tickets)
                                                                                return (
                                                                                    <div>
                                                                                        <div style={{ padding: 2, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                            <span style={{display: "flex", flexDirection: "column", fontSize: 12}}>                                                                                                
                                                                                                {
                                                                                                    tickets[1].length>1?(
                                                                                                        <span style={{display: "flex", flexDirection: "column", fontSize: 12}}>
                                                                                                            {                                                                                                                
                                                                                                                [...tickets[1]].map((a, akey) => {
                                                                                                                    return (
                                                                                                                        <div style={{fontSize: 12, width: "100%", fontWeight: "bolder"}}><span style={{textDecoration: "underline"}}>{[...user].filter((i)=>i.user_id === a.user_id)[0]?.firstname}</span> </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span style={{fontWeight: "bolder"}}>{[...user].filter((i)=>i.user_id === tickets[1][0]?.user_id)[0]?.firstname}</span>
                                                                                                    )
                                                                                                }                                                                                                
                                                                                                <span>Tickets: <span style={{fontWeight: "bolder"}}>{tickets[0]}</span>{tickets[1].length>1?` ~ (${tickets[1].length})`:""}</span>
                                                                                            </span>
                                                                                            {
                                                                                                tickets[1].length>1?(
                                                                                                    <span style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", fontSize: 12}}>
                                                                                                        {                                                                                                                
                                                                                                            [...tickets[1]].map((a, akey) => {
                                                                                                                return (
                                                                                                                    <div style={{fontSize: 12, width: "100%"}}>Php {Number(a.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                        <span style={{fontSize: 12, fontWeight: "bolder"}}>={[...tickets[1]]?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                                    </span>
                                                                                                ) : <span style={{fontSize: 12}}>Php {[...tickets[1]]?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                            } 
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                                                            <span style={{fontSize: 20, color: "#000", fontWeight: "bolder"}}>={[...item[1]]?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            )
                                                            // console.log(datetime, item[0], item[1])
                                                            // return (
                                                            //     <TicketPanel key={key} item={item} items={items} user={user} />
                                                            // )
                                                            // return (
                                                            //     <div key={key} style={{ display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                                                            //         <div key={key} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5, flexGrow: 1 }}>
                                                            //             <div key={key} style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                                            //                 <span className='circle' style={{ borderRadius: 100, width: 40, height: 40, border: "1px solid #ddd", padding: 5, fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff" }}>{item[0]?.split('-')[0]}</span>
                                                            //                 <span className='circle' style={{ borderRadius: 100, width: 40, height: 40, border: "1px solid #ddd", padding: 5, fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff" }}>{item[0]?.split('-')[1]}</span>
                                                            //             </div>
                                                            //             <div>
                                                            //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                                            //                     <span>Count: {item[1].length}</span>
                                                            //                 </div>
                                                            //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                                            //                     <span>Total {item[1]?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                            //                 </div>
                                                            //             </div>
                                                            //         </div>
                                                            //     </div>
                                                            // )
                                                        })
                                                    }
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "rgb(148 255 234)", borderRadius: 10, padding: 5 }}>
                                                        <span>{format(new Date(datetime), 'MMMM dd, yyyy')}</span>
                                                        <span style={{fontSize: 20, color: "#000", fontWeight: "bolder", textDecoration: "underline"}}>Total: Php {[...items[0].list]?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                // [...result].filter((i)=>i).map((item, key)=>{
                                //     return (                                        
                                //         <div key={key} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey"}}>
                                //             <div key={key} style={{display: "flex", flexDirection: "row", gap: 5}}>
                                //                 <span className='circle' style={{borderRadius: 100, width: 40, height: 40, border: "1px solid #ddd", padding: 5, fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{item.number.split('-')[0]}</span>
                                //                 <span className='circle' style={{borderRadius: 100, width: 40, height: 40, border: "1px solid #ddd", padding: 5, fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{item.number.split('-')[1]}</span>
                                //             </div>
                                //             <div>
                                //                 <span>{item.time}</span>
                                //             </div>
                                //             <div>
                                //                 <span>{item.date_time.split(" ")[0]}</span>
                                //             </div>
                                //         </div>
                                //     )
                                // })
                            }
                        </div>
                    </div>

                    
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default AdminTickets;
