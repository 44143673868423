import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Dashboard = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [wallet, setWallet] = useState({"balance": "0.00"})
    const [result, setResult] = useState([])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("sevendaysresultlist", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setResult(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    // useMemo(async()=> {
    //     var formData = new FormData();
    //     formData.append("result", 1);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'api/process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         setResult(res.data[0])
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // }, [ENV])
    

    const groupedData = result?.reduce((groups, item) => {
        const { datetime } = item;
        // console.log(product_id)
        if (datetime == 0) {
            if (!groups[datetime]) {
                groups[datetime] = [];
            }
            groups[datetime].push(item);
        } else {
            if (!groups[datetime]) {
                groups[datetime] = [];
            }
            groups[datetime].push(item);
        }
        return groups;
    }, {});

    console.log(groupedData)

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header wallet={wallet} hidebalance={true} />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{backgroundImage: `url(${require('./includes/sball.png')})`}}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <section className="content-header">
                            {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Dashboard</h4>
                                    </div>
                                </div>
                            </div> */}
                        </section>
                        {/* <div className="row">
                            <div className="col-lg-3 col-12">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h6>EZ2 2D</h6>
                                        <p>Total Bet: 0.00</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-shopping-cart" />
                                    </div>
                                    <a href="/lottery" className="small-box-footer">
                                        Result <i className="fas fa-arrow-circle-right" />
                                    </a>
                                </div>
                            </div>
                        </div> */}

                        <div>                                
                            <div>
                                <div className="row">
                                    <div style={{ paddingTop: 5, paddingBottom: 5, width: "100%" }}>
                                        <h5 className="mb-2" style={{fontWeight: "bolder"}}>PRIZES</h5>
                                        <div style={{fontSize: 15}}>Standard Play (getting all Two numbers drawn in exact order)</div>
                                        <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 5, border: "1px solid #fff", borderRadius: 5, backgroundColor: "rgb(200 255 231)", boxShadow: "0px 1px 2px rgb(200 255 231)"}}>
                                                <span>(per 5.00 play)</span>
                                                <span>₱3,600</span>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 5, border: "1px solid #fff", borderRadius: 5, backgroundColor: "rgb(59 194 255)", color: "#fff", boxShadow: "0px 1px 2px rgb(59 194 255)"}}>
                                                <span>(per 10.00 play)</span>
                                                <span>₱7,200</span>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 5, border: "1px solid #fff", borderRadius: 5, backgroundColor: "rgb(183 152 247)", color: "#fff", boxShadow: "0px 1px 2px rgb(183 152 247)"}}>
                                                <span>(per 15.00 play)</span>
                                                <span>₱10,800</span>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 5, border: "1px solid #fff", borderRadius: 5, backgroundColor: "rgb(247 152 162)", color: "#fff", boxShadow: "0px 1px 2px rgb(247 152 162)"}}>
                                                <span>(per 20.00 play)</span>
                                                <span>₱14,400</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "100%", border: "1px solid #ddd", marginTop: 10, marginBottom: 10}}></div>
                                <div className='row'>
                                    <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", padding: 10, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff", marginBottom: 10}}>
                                        {/* <span onClick={()=>window.location.assign('/wallet')} style={{padding: 5, border: "1px solid #ddd", backgroundColor: "#17a2b8", color: "#fff", borderRadius: 5, fontWeight: 600}}>Buy Credits</span> */}
                                        <div style={{fontWeight: "bolder"}}>Balance</div>
                                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end"}}>
                                            {/* <div>Balance</div> */}
                                            <div>
                                                <GetBalance wallet={wallet} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="mb-2" style={{fontWeight: "bolder"}}>RESULT</h5>
                                <div className="row">
                                    <div style={{ display: "flex", flexDirection: "column", gap: 5, flexGrow: 1 }}>
                                        {/* {
                                            result?.result_today?.length > 0 ?
                                                (
                                                    result?.result_today.map((item, key) => {
                                                        return (
                                                            <div key={key} style={{ width: "100%", height: 70, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ padding: 5, display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                                        <span style={{ minWidth: 45, minHeight: 45, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: 20, fontWeight: "bolder", borderRadius: 100, backgroundColor: "#000", color: "#fff" }}>{item?.number?.split('-')[0]}</span>
                                                                        <span style={{ minWidth: 45, minHeight: 45, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: 20, fontWeight: "bolder", borderRadius: 100, backgroundColor: "#000", color: "#fff" }}>{item?.number?.split('-')[1]}</span>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                        <div style={{ fontWeight: "bolder" }}>STL</div>
                                                                        <div>{item.drawtime}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                )
                                                : null
                                        } */}
                                        {                                            
                                            Object.entries(groupedData).map(([datetime, items]) => {
                                                return (
                                                    <>
                                                        <div style={{padding: "0px 0px 0px 5px"}}>{datetime}</div>
                                                        <div style={{display: 'flex', flexDirection: "column", gap: 5, width: "100%"}}>
                                                            {
                                                                [...items].filter((i) => i).map((item, key) => {
                                                                    return (
                                                                        <div key={key} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5, boxShadow: "0px 1px 2px grey" }}>
                                                                            <div key={key} style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                                                                <span className='circle' style={{ borderRadius: 100, width: 40, height: 40, border: "1px solid #ddd", padding: 5, fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff" }}>{item.number.split('-')[0]}</span>
                                                                                <span className='circle' style={{ borderRadius: 100, width: 40, height: 40, border: "1px solid #ddd", padding: 5, fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff" }}>{item.number.split('-')[1]}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span>{item.time}</span>
                                                                            </div>
                                                                            {/* <div>
                                                                                <span>{item.date_time.split(" ")[0]}</span>
                                                                            </div> */}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                    <div>YESTERDAY</div>
                                </div>
                                <div className="row">
                                    <div style={{ display: "flex", flexDirection: "column", gap: 5, flexGrow: 1 }}>
                                        {
                                            result?.result_yesterday?.length > 0 ?
                                            result?.result_yesterday.map((item, key) => {
                                                return (
                                                    <div key={key} style={{ width: "100%", height: 70, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                        <div style={{ padding: 5, display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                                <span style={{ minWidth: 45, minHeight: 45, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: 20, fontWeight: "bolder", borderRadius: 100, backgroundColor: "#000", color: "#fff" }}>{item?.number?.split('-')[0]}</span>
                                                                <span style={{ minWidth: 45, minHeight: 45, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: 20, fontWeight: "bolder", borderRadius: 100, backgroundColor: "#000", color: "#fff" }}>{item?.number?.split('-')[1]}</span>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                <div style={{ fontWeight: "bolder" }}>STL</div>
                                                                <div>{item.drawtime}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <br />
                        <br />
                        <br />
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Dashboard;
