import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import GetBalance from './extra/GetBalance';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import ConvertForm from './component/commission/ConvertForm';
import History from './component/commission/History';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Commission = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';
    const [date, setDate] = useState(null);

    const [commission, setCommission] = useState([])
    const [user, setUser] = useState([])
    const [wallet, setWallet] = useState({"balance": "0.00"})

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("fetchcommission", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setCommission(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    const loadcommission = async () => {
        var formData = new FormData();
        formData.append("fetchcommission", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setCommission(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }    
    useMemo(async()=> {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    useMemo(async()=> {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    const loadbalance = async () => {
        var formData = new FormData();
        formData.append("getbalance", 1);
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            setWallet(res.data[0])
        })
        .catch((err) => {
            console.log(err);
        });
    }


    const newData = commission.filter((i)=>i.user_id === sessionStorage.getItem('user_id') && i?.date_time.toLowerCase().includes(format(date !== null ? date : new Date(), 'yyyy-MM-dd')?.toLowerCase()))

    // console.log(format(date !== null ? date : new Date(), 'yyyy-MM-dd'))

    const TotalCommission = commission?.reduce((amount, item) => amount = Number(item.amount) + amount, 0);

    const ClaimBtn = async (item) => {
        alert(JSON.stringify(item))
        var formData = new FormData();
        formData.append("convert_to_wallet", 1);
        formData.append("item", JSON.stringify(item));
        formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            loadbalance();
            loadcommission()
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header wallet={wallet} titlepage="Commission" />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{backgroundImage: `url(${require('./includes/sball.png')})`}}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    {/* <div className="container-fluid">
                        <section className="content-header"> */}
                            {/* <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h4>Account</h4>
                                    </div>
                                </div>
                            </div> */}
                        {/* </section>
                    </div> */}

                    <div className="row">
                        <div style={{width: "100%", padding: 5, display: "flex", flexDirection:"row", justifyContent: "space-between", alignItems: "center"}}>
                            {/* <span style={{fontWeight: "bolder", fontSize: 20}}>Commission</span> */}
                            <Calendar value={date} onChange={(e) => setDate(e.value)} dateFormat="yy-mm-dd" style={{height: 35, width: 120}} />
                        </div>
                        <div style={{width: "100%", padding: 5, paddingTop: 2, display: "flex", flexDirection:"row", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{fontWeight: "bolder"}}>Total Commission: 
                                {
                                    date !== null ?
                                    " " + Number(newData?.reduce((amount, item) => amount = Number(item.amount) + amount, 0))
                                    :
                                    " " + Number(commission.filter((i)=>i.user_id === sessionStorage.getItem('user_id'))?.reduce((amount, item) => amount = Number(item.amount) + amount, 0))
                                }
                            </span>
                            <span></span>
                        </div>
                        {/* <div style={{width: "100%", padding: 5, paddingTop: 2, display: "flex", flexDirection:"row", justifyContent: "space-between", gap: 10, alignItems: "center"}}>
                            <span style={{fontWeight: "bolder"}}>Converted: {Number(wallet.conversion)}</span>
                            {
                                Number(TotalCommission-wallet.conversion)>0 ?
                                    <div style={{display: "flex", flexDirection:"row", justifyContent: "flex-end", gap: 20}}>
                                        <span>
                                            <ConvertForm commission={TotalCommission} wallet={wallet} />
                                        </span>
                                        {
                                            Number(wallet.conversion)>0 ?
                                                <History commission={TotalCommission} wallet={wallet} />
                                            : null
                                        }
                                    </div>
                                : 
                                (
                                    Number(wallet.conversion)>0 ?
                                        <History commission={TotalCommission} wallet={wallet} />
                                    : null
                                )
                            }
                        </div> */}


                        <div style={{display: 'flex', flexDirection: "column", gap: 5, width: "100%"}}>
                            {
                                [...newData].filter((i)=>i.user_id === sessionStorage.getItem('user_id')).map((item)=>{
                                    // console.log([...user].filter((i)=>i?.user_id === item.from_user_id)[0]?.firstname)
                                    return (
                                        <div style={{display: "flex", flexDirection: "column", gap: 0, backgroundColor: "#fff", flexGrow: 1, padding: 5, borderRadius: 5}}>
                                            {
                                                item?.from_user_id === sessionStorage.getItem('user_id') ?
                                                <span>From: You</span>
                                                :
                                                <span>From: {[...user].filter((i)=>i?.user_id === item?.from_user_id)[0]?.firstname}</span>
                                            }
                                            <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                <span>Amount: {item?.amount}</span>
                                            </div>
                                            <span>{item?.date_time}</span>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                                                {
                                                    item?.type === 'claimed' ?
                                                    <button className='btn btn-default btn-sm'>Claimed</button>
                                                    :
                                                    <button className='btn btn-success btn-sm' onClick={()=>ClaimBtn(item)}>Transfer Credit</button>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                {/* <div style={{position: "fixed", zIndex: 99999, bottom: 0, width: "100%", backgroundColor: "#fff"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems:"center", flexShrink: 1}}>
                        <div onClick={()=>window.location.assign("/dashboard")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Home</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/lottery")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Play</div>
                        <div style={{height: 30, width: 1, backgroundColor: "#ddd"}}></div>
                        <div onClick={()=>window.location.assign("/account")} style={{width: "33.33%", textAlign:"center", backgroundColor: "#fff", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>Account</div>
                    </div>
                </div> */}
            </div>
        </div>

    );
}

export default Commission;

