import React, { useContext, useState } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import { useEffect } from 'react';
import AppContext from './context/AppContext';
import io from "socket.io-client";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

const Register = () => {
    
    const history = useHistory()
    const { user_id, refid } = useParams();


    const [inputvalue, setInputValue] = useState({"refid": "", "firstname": "", "middlename": "", "lastname": "", "username": "", "password": "", "contact": "", "email": ""})
    // console.log(inputvalue)
    // const socket = io.connect("http://192.168.1.11:3001");

    // const {state, dispatch} = useContext(AppContext);

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
                setWidth(window.innerWidth);
        }
    
        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);
    
        return (width <= 768);
    }
    
    

    const RegisterBtn = async (event) => {
        event.preventDefault();

        

        var formData = new FormData();
        formData.append("register", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));

        if(inputvalue.username === '' && inputvalue.password === ''){
            swal({
                title: "Invalid!",
                text: "Please Enter username and password",
                icon: "error",
                timer: 1000,
                button: false,
            });
            return;
        }

        if (inputvalue.refid === '') {
            swal({
                title: "Invalid!",
                text: "Please Enter Referral Code",
                icon: "error",
                timer: 1000,
                button: false,
            });
            return;
        }

        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            // return
            if (res.data[0].status === 'success') {
                swal({
                    // title: "",
                    text: "Successfully Registered",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(()=>{
                    // sessionStorage.setItem('user_id', res.data[0].user_id)
                    // sessionStorage.setItem('username', res.data[0].username)
                    // sessionStorage.setItem('position', res.data[0].position)
                    if (res.data[0].position === 'cashier') {
                        // window.location.assign("/")
                    } else {
                        // window.location.assign("/patient")
                    }
                });
            } else if (res.data[0].status === 'existing_username') {
                swal({
                    // title: "",
                    text: "Invalid Username",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(()=>{

                });
            } else if (res.data[0].status === 'invalid_email') {
                swal({
                    // title: "",
                    text: "Invalid Email Address",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(()=>{

                });
            } else if (res.data[0].status === 'existing_email') {
                swal({
                    // title: "",
                    text: "Already used Email Address",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(()=>{

                });
            } else if (res.data[0].status === 'referral_does_not_exist') {
                swal({
                    // title: "",
                    text: "could not find this referral",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(()=>{

                });
            } else {
                swal({
                    // title: "Invalid!",
                    text: "Incorrect username and password",
                    icon: "error",
                    timer: 2000,
                    button: false,
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    // console.log(state.user_id, Number(sessionStorage.getItem('user_id')))






    return (
        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height: window.innerHeight, paddingTop: 100}}>
            {/* <video src={require('./356955600_6510487162366595_2239991482821432230_n.mp4')} style={{ width:"100%", height:"100%", objectFit:"cover"}} autoPlay loop muted /> */}
            <div className="login-box" style={{opacity:0.89, position:"absolute"}}>
                <div className="login-logo">
                    <img src={require('./includes/437803647_1158427041842714_2812090053231683581_n.jpg')} style={{width:200, height:200, borderRadius:100}} />
                    {/* <div><a>Sureball</a></div> */}
                </div>
                {/* /.login-logo */}
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Registration</p>
                        <form onSubmit={RegisterBtn} method="post">
                            <div className="input-group mb-3">
                                <input type="text" name='firstname' onChange={(e)=>setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.firstname} className="form-control" placeholder="First Name" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" name='middlename' onChange={(e)=>setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.middlename} className="form-control" placeholder="Middle Name" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" name='lastname' onChange={(e)=>setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.lastname} className="form-control" placeholder="Last Name" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="number" name='contact' onChange={(e)=>setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.contact} className="form-control" placeholder="Contact No." />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-phone" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" name='email' onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} value={inputvalue.email} className="form-control" placeholder="Email" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-at" />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="input-group mb-3">
                                <input type="text" name='username' onChange={(e)=>setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.username} className="form-control" placeholder="Username" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" name='password' onChange={(e)=>setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.password} className="form-control" placeholder="Password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" name='refid' onChange={(e)=>setInputValue(prev =>({...prev, [e.target.name]: e.target.value}))} value={inputvalue.refid} className="form-control" placeholder="Referral Code" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="pi pi-ticket" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                        <a href='../' style={{color: "green", textDecoration: "underline"}}>Sign In</a>
                                </div>
                                {/* /.col */}
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Register</button>
                                </div>
                                {/* /.col */}
                            </div>
                        </form>
                    </div>
                    {/* /.login-card-body */}
                </div>
            </div>
        </div>

    );
}

export default Register;
