import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
// import '../../../payout.css';
import axios from 'axios';
import ENV from '../../../ENV';

const AdminResultList = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({...props?.item})
    const hideDialog = (event) => {
        setOpenDialog(prev => ({ ...prev, open: false }))
    }

    const [user, setUser] = useState([])
    useMemo(async()=> {
        var formData = new FormData();
        formData.append("fetchuser", 1);
        // formData.append("user_id", sessionStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN+'api/process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setUser(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }, [ENV])

    return (
        <>
        <Sidebar
            blockScroll={true}
            position='bottom'
            draggable={false}
            style={{ width: '100rem', height: 500, fontSize: 15 }} //, height: 500
            visible={openDialog.open}
            onHide={hideDialog}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    {
                        [...props.resultwinner].filter((i)=>i.status === 'WIN' && i.number === props.item.number && i.time_draw === props.item.draw_time && i.datetime === props.item.datetime)
                        .map((item)=>{
                            return (
                                <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems: "center", padding: 5, boxShadow: "0px 1px 2px grey"}}>
                                    <span>{[...user].filter((i)=>i.user_id === item.user_id)[0]?.firstname}</span>
                                    <span>{Number(item.reward).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </Sidebar>
            {/* {JSON.stringify(props.item)} */}
            {/* <div onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{ padding: 0, width: 40, textAlign: "center", borderRadius: 5, border: "1px solid #ddd" }}>
                <i className='pi pi-list' />
            </div> */}
            
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                {
                    [...props.resultwinner].filter((i)=>i.status === 'WIN' && i.number === props.item.number && i.time_draw === props.item.draw_time && i.datetime === props.item.datetime).length > 0 ?
                    <span>Winner: <span style={{textDecoration: "underline"}} onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))}>{[...props.resultwinner].filter((i)=>i.status === 'WIN' && i.number === props.item.number && i.time_draw === props.item.draw_time && i.datetime === props.item.datetime).length}</span></span>
                    :
                    <span>Winner: <span>0</span></span>
                }
                <span>Total Reward: <span>{[...props.resultwinner]?.filter((i)=>i.status === 'WIN' && i.number === props.item.number && i.time_draw === props.item.draw_time && i.datetime === props.item.datetime)?.reduce((amount, item) => amount = Number(item.reward) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
            </div>
        </>
    );
}

export default AdminResultList;
