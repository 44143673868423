// import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import swal from 'sweetalert';
import './wallet.css';

const WalletForm = (props) => {
    const [openDialog, setOpenDialog] = useState({ open: false })
    const [inputvalue, setInputValue] = useState({ "user_id": sessionStorage.getItem('user_id'), "account_name": "", "account_number": "", "amount": '' })
    // console.log(inputvalue)
    const hideDialog = (event) => {
        // alert("")
        // console.log(event)
        setOpenDialog(prev => ({ ...prev, open: false }))
    }
    // console.log(props?.wallet?.balance, Number(inputvalue.amount))
    // console.log(props?.wallet?.balance <= Number(inputvalue.amount))

    const sendRequet = async (event) => {
        event.preventDefault();

        var formData = new FormData();
        formData.append("reqest_payout", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));

        if (inputvalue.amount<0) {
            swal({
                title: "Invalid!",
                text: "Invalid Amount",
                icon: "error",
                timer: 2000,
                button: false,
            });
            return;
        }

        if (inputvalue.account_name === '' || inputvalue.account_number === '' || inputvalue.amount < 0) {
            swal({
                title: "Invalid!",
                text: "Please complete the form",
                icon: "error",
                timer: 1000,
                button: false,
            });
            return;
        }


        if (props?.wallet?.balance >= Number(inputvalue.amount)) {
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'api/process.php',
                data: formData,
            }).then((res) => {
                if (res.data[0].status == 'success') {
                    swal({
                        // title: "",
                        text: "Successfully Sent Request",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        setOpenDialog(prev => ({ ...prev, open: false }))
                        setInputValue({ "user_id": sessionStorage.getItem('user_id'), "account_name": "", "account_number": "", "amount": '' })
                        props.reloadWallet()
                        props.reloadPayoutList()
                    });
                } else if (res.data[0].status == 'invalid_amount') {
                    swal({
                        // title: "Invalid!",
                        text: "invalid Amount",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    });
                }
            })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            swal({
                // title: "Invalid!",
                text: "not enough balance!",
                icon: "error",
                timer: 1000,
                button: false,
            });
        }
    }

    return (
        <>
            <Sidebar
                icons={
                    <div style={{ width: "100%", textAlign: "left", fontSize: 20, fontWeight: "bolder" }}>Payout Request</div>
                }
                blockScroll={true}
                position='bottom'
                draggable={false}
                style={{ width: '100rem', height: 350, fontSize: 15 }}
                visible={openDialog.open}
                onHide={hideDialog}>
                <form onSubmit={sendRequet} method="post">
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 10 }}>
                        <div>
                            <label>GCash Account Name</label>
                            <input className='form-control' name="account_name" onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.account_name} />
                        </div>
                        <div>
                            <label>GCash Number</label>
                            <input className='form-control' name="account_number" onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.account_number} />
                        </div>
                        <div>
                            <label>Amount</label>
                            <input type='number' className='form-control' name="amount" onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} value={inputvalue.amount} />
                        </div>
                        <div>
                            <button type="submit" className='btn btn-info btn-sm btn-block'>Send Request Payout</button>
                        </div>
                    </div>
                </form>
            </Sidebar>

            <button onClick={() => setOpenDialog(prev => ({ ...prev, "open": true }))} style={{ width: 100 }} className='btn btn-info btn-sm'>Payout</button>
        </>
    );
}

export default WalletForm;
